import React, {useEffect, useState, useCallback } from 'react';
import { ReadDB, GetDeptVals, LineChart,GetDataCounts } from "../imports";

function DepartmentAreaChart({ date, selectedDept }) {
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const departments = ['Shipping','Receiving','Detamper','Repair','Diagnostics','Cleaning','Firmware','Rki'];
    const [dataset, SetData] = useState(null);
    const selectedPart = "PSTA3-000-R";

    const PullData = (date)=>{
        date = date.toString().slice(0,2)+'-'+date.toString().slice(-2);
        if (Window.counts[date] === undefined){
            ReadDB(`MainCounts/${date}`, (data)=>{
                Window.counts[date] = data;
                SetData(data);
            });
            return;
        }
        SetData(Window.counts[date]);
    }

    useEffect(()=>{
        PullData(date);
    }, [date, selectedDept]);

    useEffect(()=>{
        SetCounts(dataset);
    },[dataset]);

    useEffect(()=>{
        SetCounts(Window.counts[date.toString().slice(0,2)+'-'+date.toString().slice(-2)]);
    },[selectedDept]);

    const SetCounts = (data)=>{
        let counts = GetDataCounts(data);
        selectedDept = selectedDept.toString().toLowerCase();

        let chartSeries = [];
        let labels =[];
        let targets = [];
        let actuals =[];

        let aTotal = 0;
        let tTotal = 0;

        Object.keys(counts[selectedDept]).map((date)=>{
            if (Object.keys(counts[selectedDept][date]).length === 0){ return; }
            
            let t = 0; // target actual
            let a = 0;
            
            a += (counts[selectedDept][date][selectedPart]?.count || 0);
            t += (counts[selectedDept][date][selectedPart]?.target || 0);
            a += (counts[selectedDept][date][selectedPart.toString().replace('-R','')]?.count || 0);
            t += (counts[selectedDept][date][selectedPart.toString().replace('-R','')]?.target || 0);

            if (a > 0){
                labels.push(date.toString().slice(0,5));
                aTotal += a;
                tTotal += t;

                targets.push(t);
                actuals.push(a);
            }
            
        });

        targets = targets.map((t)=>{ return Math.round(t); });
        if (tTotal === 0){ targets[targets.length -1] += 1; }
        if (aTotal === 0){ actuals[actuals.length -1] += 1; } // apex charts throws error when hovering on last col if all 0's

        chartSeries.push({ name : 'count', data : actuals });
        chartSeries.push({ name : 'target', data : targets });

        setChartSeries(chartSeries);
        setLabels(labels);
        setChartVals(true);
        // labels  -  counts  / targets
    }
    
    const [chartData, setChartSeries] = useState(null);
    const [labelz, setLabels] =  useState(null);
    const [chartVals, setChartVals] = useState(null);

    return (
    <div className = "absolute inset-0" id = "chart">
        { chartVals ? <LineChart series = { chartData } labels = { labelz } department = {selectedDept} /> : <div></div> }
    </div>
  )
}

export default DepartmentAreaChart;

const ignore = ['hours','eHours'];