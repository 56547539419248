import React, { useEffect, useRef } from 'react';
import logo from "../prestoWhite.webp";
import LoginButton from "../googlesignin.png";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
const firebaseConfig = { apiKey: "AIzaSyAINs6MknkK2mHq-DHvFCG5MtHtgEqlDE4", authDomain: "presto-supply-chain.firebaseapp.com", projectId: "presto-supply-chain", storageBucket: "presto-supply-chain.appspot.com", messagingSenderId: "869553006991", appId: "1:869553006991:web:7c9b66e7264967072343fa" };
initializeApp(firebaseConfig); const auth = getAuth(); const provider = new GoogleAuthProvider(); provider.setCustomParameters({ 'login_hint': 'user@presto.com' });
const googleProvider = new GoogleAuthProvider();
const database = getDatabase();
Window.database = database;
Window.auth = auth;


const signIn = async ()=>{
  try{
    const response = await signInWithPopup(auth, googleProvider);
    const user = response.user;
    console.log(user);
  }catch(e){
    console.log(e);
  }
}

function Login() {
  let loginBox = useRef(null);
  
  useEffect(()=>{
    loginBox.current.className = "active";
  });

  return (
    <div className = "flex items-center justify-center absolute inset-0" id = "loginPage">
      <div ref = { loginBox } className = "">
        <div>
          <div className = "container flex flex-col gap-6 mt-6 align-center">
            <img src= { logo } alt="Logo" className = "noSelect mx-auto mt-2 h-[23%] opacity-90"/>
            <div className = "noSelect mx-auto mt-2 text-lg text-neutral-400">Supply Chain</div>
          </div>
          <button className = "googleSignIn mx-auto mb-auto mt-10 relative"><img alt = "Login" src = { LoginButton } onClick = { signIn } ></img></button>
        </div>
        
      </div>
    </div>
    
  )
}

export default Login
