import React, { useState, useEffect, createRef, useRef } from 'react';
import { DateSelector, DepartmentSelector, DevTable, WriteDB, ReadDB, IdPart, CheckSerialized, FixDate, Sleep, DownloadCSV } from '../imports';
import { ref, get, set, child, onValue} from "firebase/database";
Window.testMode = false;

function Dev() {

    const [selectedDept, SetDept] = useState('Shipping');
    const [selectedImport, setImportDept] = useState('Shipping');
    const [selectedDate, SetDate] = useState(null);
    const [Csv, SetCsv] = useState(null);

    const headers = {
        Shipping : ["Date","Employee","Franchise","Customer","Zendesk Ticket","Part Number","Quantity","Serial Number","Comments","Comments2","Tracking"],
        Receiving : ["Date","Employee","Franchise","Customer","Zendesk Ticket","Part Number","Quantity","Serial Number","Status","Status2","Tracking"],
        Repair : ["Date","Employee","Serial Number","MAC Address","Status"],
        Detamper : ["Date","Employee","Serial Number","MAC Address","Initial Problem", "Result"],
        Rki : ['Date','Employee','Serial Number', 'KeyType'],
        Inventory : ["Date","Part Number","Serial Number","Location","Area","Rack","Shelf","Quantity"],
        Scrap : ["Date","Part Number","Serial Number","Quantity"],
        Transfers : ["Date","Part Number","Serial Number","Quantity","From Location","To Location"]
    }

    const UploadFile = (event)=>{
        event.preventDefault();
        let file = event.target.files[0];
        if (file){
            let filetype = file.name.toString().split('.')[file.name.toString().split('.').length - 1];
            if (filetype !== "csv"){ return; }
            let fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onload = function(){
                let rows = fileReader.result;
                rows = rows.toString().split('\r\n');
                rows.map((row, i)=>{ rows[i] = row.toString().split(',') });
                SetCsv(rows);
            }
        }
    }

    const setDate = (val)=>{ SetDate(val); }
    const setDept = (val)=>{ SetDept(val); }

    const [importActive, setImportActive] = useState("");

    const ToggleImports = ()=>{
        if (importActive === ""){
            setImportActive("activeImport");
        }else{
            setImportActive("");
        }
    }

    const [logs, setLogs] = useState("");

    const logRef = createRef();
    useEffect(()=>{
        logRef.current?.scrollIntoView({behavior : 'smooth'});
    },[logs]);
    const UpdateLog = (text)=>{
        let log = logs;
        log += (text || "").toString()+'\n';
        setLogs(log);
    }

    const HandleWrite = ()=>{
        if (selectedImport === "writeOp"){
            WriteOp();
            return;
        }
        if (!Csv){
            UpdateLog(`No File Selected`);
            return;
        }

        UpdateLog(`Importing ${selectedImport}`);

        switch (selectedImport){
            case 'Shipping' :
                WriteShipping();
                break;
            case 'Receiving' :
                WriteReceiving();
                break;
            case 'Detamper' :
                WriteDetamper();
                break;
            case 'Repair' :
                WriteRepair();
                break;
            case 'Rki' :
                WriteRki();
                break;
            case 'Scrap' :
                WriteScrap();
                break;
            case 'Transfers' :
                WriteTransfers();
                break;
            case 'Inventory' :
                WriteInventory();
                break;
            default :
                break;
        }

    }


    const WriteShipping = ()=>{
        let [dateIndex, employeeIndex, franchiseIndex, customerIndex, zdIndex, partIndex, snIndex, qtyIndex, com1Index, com2Index, trackingIndex ] = FindIndex(Csv[0], ["Date","Employee","Franchise","Customer", "Zendesk Ticket", "Part Number", "Serial Number", "Qty","Comments","Comments2", "Tracking"]);
        let indexCheck = [dateIndex, employeeIndex, franchiseIndex, customerIndex, zdIndex, partIndex, snIndex, qtyIndex, com1Index, com2Index, trackingIndex ];
        if (indexCheck.indexOf(-1) !== -1){ UpdateLog('Bad Headers'); return; }
        let fail = false;

        ReadDB(`MainCounts`, (mainCounts)=>{
            let data1 = Csv;
            data1.splice(0, 1);
            let data = [];
            
            data1.map((row, i)=>{
                if (row.join('').replaceAll(/\s/g,"") === ""){ return; }
                row[dateIndex] = FixDate(row[dateIndex]).toString().firebaseFormat('-');
                if (row[dateIndex].toString().indexOf('Na') !== -1 || row.length !== indexCheck.length){ fail = true;  console.log(i); }
                data.push(row);
            });

            if (fail){ UpdateLog('Row Length Mismatch'); return; }
            
            let dates = GetNewSet(data, dateIndex);

            dates.map((date)=>{
                let month = date.toString().getMonth();
                let dateVals = {};
                try{ dateVals = mainCounts[month][date].shipping; }catch(e){ dateVals = {}; }
                let shipping = {};
                let shipCounts = {};
                let franchiseData = {};

                let rows = [];
                data.map((row)=>{ if( row[dateIndex] === date){ rows.push(row); }});

                

                

                rows.map((row)=>{
                    let part = (row[partIndex] || "NA").firebaseFormat();
                    let sn = (row[snIndex] || "NA").firebaseFormat();
                    let qty = (row[qtyIndex] || "1").toString().quantity();
                    let employee = (row[employeeIndex] || "NA").toString().firebaseFormat();
                    let franchise = (row[franchiseIndex] || "NA").toString().firebaseFormat();
                    let customer = (row[customerIndex] || "NA").toString().firebaseFormat();
                    let zd = (row[zdIndex] || "NA").toString().firebaseFormat();
                    let tracking = (row[trackingIndex] || "NA").firebaseFormat();
                    let comments1 = (row[com1Index] || "NA").toString().firebaseFormat();
                    let comments2 = (row[com2Index] || "NA").toString().firebaseFormat();
                    let comments = [comments1, comments2];
                    

                    let isSerialized = CheckSerialized(sn, part);
                    if (!isSerialized){ sn = part; }

                    if (isSerialized){
                        if (Window.testMode){
                            console.log(`Devices/${sn}/data/shipping/${date}\n`);
                            console.log({
                                ticket : zd,
                                customer : customer,
                            });
                        }else if (!Window.testMode){
                            WriteDB(`Devices/${sn}/data/shipping/${date}`, {
                                ticket : zd,
                                customer : customer,
                            });
                        }
                    }

                    if (franchiseData[franchise] === undefined){ franchiseData[franchise] = {};}
                    if (franchiseData[franchise][customer] === undefined){ franchiseData[franchise][customer] = {}; }
                    if (franchiseData[franchise][customer][tracking] === undefined){ franchiseData[franchise][customer][tracking] = {}; }
                    if (franchiseData[franchise][customer][tracking][part] === undefined){ franchiseData[franchise][customer][tracking][part] = {}; }
                    if (franchiseData[franchise][customer][tracking][part].sns === undefined){ franchiseData[franchise][customer][tracking][part].sns = {}; }
                    if (franchiseData[franchise][customer][tracking][part].qty === undefined){ franchiseData[franchise][customer][tracking][part].qty = 0; }
                    franchiseData[franchise][customer][tracking][part].qty += qty;
                    franchiseData[franchise][customer][tracking][part].sns[sn] = { status : "Good" }


                    if (shipping[franchise] === undefined){ shipping[franchise] = {}}
                    if (shipping[franchise][customer] === undefined){ shipping[franchise][customer] = {}}
                    if (shipping[franchise][customer][zd] === undefined){ shipping[franchise][customer][zd] = {}}
                    if (shipping[franchise][customer][zd].data === undefined){ shipping[franchise][customer][zd].data = {}; }
                    if (shipping[franchise][customer][zd].data[tracking] === undefined){ shipping[franchise][customer][zd].data[tracking] = { }}
                    if (shipping[franchise][customer][zd].comments === undefined){ shipping[franchise][customer][zd].data[tracking].comments = comments; }
                    if (shipping[franchise][customer][zd].employee === undefined){ shipping[franchise][customer][zd].data[tracking].employee = employee; }
                    if (shipping[franchise][customer][zd].data[tracking][sn] === undefined){ shipping[franchise][customer][zd].data[tracking][sn] = {
                        part : part.toString(),
                        qty : 0,
                    } }

                    shipping[franchise][customer][zd].data[tracking][sn].qty += qty;

                    if (shipCounts === undefined){ shipCounts = {}; }
                    if (shipCounts[employee] === undefined){ shipCounts[employee] = {}; }
                    try{ if (dateVals[employee].hours !== undefined){ shipCounts[employee].hours = dateVals[employee].hours } }catch(e){ }
                    try{ if (dateVals[employee].eHours !== undefined){ shipCounts[employee].eHours = dateVals[employee].eHours } }catch(e){ }


                    if (shipCounts[employee][franchise] === undefined){ shipCounts[employee][franchise] = {}; }
                    if (shipCounts[employee][franchise][part] === undefined){ shipCounts[employee][franchise][part] = {}; }
                    if (shipCounts[employee][franchise][part]['good'] === undefined){ shipCounts[employee][franchise][part]['good'] = {}; }
                    if (shipCounts[employee][franchise][part]['good'].qty === undefined){ shipCounts[employee][franchise][part]['good'].qty = 0; }


                    shipCounts[employee][franchise][part]['good'].qty += qty;
                });

                Object.keys(franchiseData).map((franchise)=>{
                    Object.keys(franchiseData[franchise]).map((customer)=>{
                        Object.keys(franchiseData[franchise][customer]).map((tracking)=>{
                            let shipData = franchiseData[franchise][customer][tracking];
                            if (!shipData){ return; }
                            if (Window.testMode){
                               console.log(`Franchises/${franchise}/${customer}/${date}/shipped/${tracking}\n`);
                               console.log(shipData);
                            }else if(!Window.testMode){ WriteDB(`Franchises/${franchise}/${customer}/${date}/shipped/${tracking}`, shipData); } // max 1000 writes /s
                        });
                    });
                });

                console.log(shipCounts);
                if (!Window.testMode){ WriteDB(`MainCounts/${month}/${date}/shipping/data`, shipCounts)}
                if (!Window.testMode){ WriteDB(`Main/${month}/${date}/shipping`, shipping)}

            });

            
            UpdateLog(` [  ${dates.join(', ')}  ] \n imported ${data.length} Shipping rows`);
        });
        if (fail){
            UpdateLog(`Failed`);
            return;
        }
        
    }

    const WriteReceiving = ()=>{
        let [dateIndex, employeeIndex, franchiseIndex, customerIndex, zdIndex, partIndex, snIndex, qtyIndex, statusIndex, status2Index, trackingIndex ] = FindIndex(Csv[0], ["Date","Employee","Franchise","Customer", "Zendesk Ticket", "Part Number", "Serial Number", "Qty","Status","Status2", "Tracking"]);
        let indexCheck = [dateIndex, employeeIndex, franchiseIndex, customerIndex, zdIndex, partIndex, snIndex, qtyIndex, statusIndex, status2Index, trackingIndex ];
        if (indexCheck.indexOf(-1) !== -1){ UpdateLog(`Bad Headers`); return; }
        let fail = false;

        ReadDB(`MainCounts`, (mainCounts)=>{
            let data1 = Csv;
            data1.splice(0, 1);
            let data = [];

            
            data1.map((row)=>{
                if (row.join('').replaceAll(/\s/g,"") === ""){ return; }
                row[dateIndex] = FixDate(row[dateIndex]).toString().firebaseFormat('-');
                if (row[dateIndex].toString().indexOf('Na') !== -1 || row.length !== indexCheck.length){ fail = true; }
                data.push(row);
            });

            if (fail){ return; }
            
            let dates = GetNewSet(data, dateIndex);
            

            dates.map((date)=>{
                let month = date.toString().getMonth();
                let dateVals = {}; // og counts to save targets
                try{ dateVals = mainCounts[month][date].receiving; }catch(e){ dateVals = {}; }
                let receiving = {};
                let receiveCounts = {};

                let franchiseData = {};

                let rows = [];
                data.map((row)=>{ if( row[dateIndex] === date){ rows.push(row); }});

                

                

                rows.map((row)=>{
                    let part = (row[partIndex] || "NA").firebaseFormat();
                    let sn = (row[snIndex] || "NA").firebaseFormat();
                    let qty = (row[qtyIndex] || "1").toString().quantity();
                    let employee = (row[employeeIndex] || "NA").toString().firebaseFormat();
                    let franchise = (row[franchiseIndex] || "NA").toString().firebaseFormat();
                    let customer = (row[customerIndex] || "NA").toString().firebaseFormat();
                    let zd = (row[zdIndex] || "NA").toString().firebaseFormat();
                    let tracking = (row[trackingIndex] || "NA").firebaseFormat();
                    let status1 = (row[statusIndex] || "NA").toString().firebaseFormat();
                    let status2 = (row[status2Index] || "NA").toString().firebaseFormat();
                    

                    let isSerialized = CheckSerialized(sn, part);
                    if (!isSerialized){ sn = part; }

                    if (isSerialized){
                        if (Window.testMode){
                            console.log(`Devices/${sn}/data/receiving/${date}\n`);
                            console.log({
                                status : status1,
                                status2 : status2,
                                ticket : zd,
                                customer : customer,
                            });
                        }else if (!Window.testMode){
                            WriteDB(`Devices/${sn}/data/receiving/${date}`, {
                                status : status1,
                                status2 : status2,
                                ticket : zd,
                                customer : customer,
                            });
                        }
                    }

                    if (franchiseData[franchise] === undefined){ franchiseData[franchise] = {};}
                    if (franchiseData[franchise][customer] === undefined){ franchiseData[franchise][customer] = {}; }
                    if (franchiseData[franchise][customer][tracking] === undefined){ franchiseData[franchise][customer][tracking] = {}; }
                    if (franchiseData[franchise][customer][tracking][part] === undefined){ franchiseData[franchise][customer][tracking][part] = {}; }
                    if (franchiseData[franchise][customer][tracking][part].sns === undefined){ franchiseData[franchise][customer][tracking][part].sns = {}; }
                    if (franchiseData[franchise][customer][tracking][part].qty === undefined){ franchiseData[franchise][customer][tracking][part].qty = 0; }
                    franchiseData[franchise][customer][tracking][part].qty += qty;
                    franchiseData[franchise][customer][tracking][part].sns[sn] = { status : status1 }



                    if (receiving[franchise] === undefined){ receiving[franchise] = {}}
                    if (receiving[franchise][customer] === undefined){ receiving[franchise][customer] = {}}
                    if (receiving[franchise][customer][zd] === undefined){ receiving[franchise][customer][zd] = {}}
                    if (receiving[franchise][customer][zd].data === undefined){ receiving[franchise][customer][zd].data = {}; }
                    if (receiving[franchise][customer][zd].data[tracking] === undefined){ receiving[franchise][customer][zd].data[tracking] = {}}
                    if (receiving[franchise][customer][zd].data[tracking].employee === undefined){ receiving[franchise][customer][zd].data[tracking].employee = employee; }
                    if (receiving[franchise][customer][zd].data[tracking][sn] === undefined){ receiving[franchise][customer][zd].data[tracking][sn] = {
                        part : part.toString(),
                        status : status1,
                        status2 : status2,
                        qty : 0,
                    } }

                    receiving[franchise][customer][zd].data[tracking][sn].qty += qty;

                    if (receiveCounts === undefined){ receiveCounts = {}; }
                    if (receiveCounts[employee] === undefined){ receiveCounts[employee] = {}; }
                    try{ if (dateVals[employee].hours !== undefined){ receiveCounts[employee].hours = dateVals[employee].hours } }catch(e){ }
                    try{ if (dateVals[employee].eHours !== undefined){ receiveCounts[employee].eHours = dateVals[employee].eHours } }catch(e){ }


                    if (receiveCounts[employee][franchise] === undefined){ receiveCounts[employee][franchise] = {}; }
                    if (receiveCounts[employee][franchise][part] === undefined){ receiveCounts[employee][franchise][part] = {}; }
                    if (receiveCounts[employee][franchise][part][status1] === undefined){ receiveCounts[employee][franchise][part][status1] = {}; }
                    if (receiveCounts[employee][franchise][part][status1].qty === undefined){ receiveCounts[employee][franchise][part][status1].qty = 0; }


                    receiveCounts[employee][franchise][part][status1].qty += qty;
                });

                Object.keys(franchiseData).map((franchise)=>{
                    Object.keys(franchiseData[franchise]).map((customer)=>{
                        Object.keys(franchiseData[franchise][customer]).map((tracking)=>{
                            let recData = franchiseData[franchise][customer][tracking];
                            if (!recData){ return; }
                            if (Window.testMode){
                                console.log(`Franchises/${franchise}/${customer}/${date}/received/${tracking}\n`);
                                console.log(recData);
                            }else if(!Window.testMode){ WriteDB(`Franchises/${franchise}/${customer}/${date}/received/${tracking}`, recData); } // max 1000 writes /s
                        });
                    });
                });

                console.log(receiveCounts);
                if (!Window.testMode){ WriteDB(`MainCounts/${month}/${date}/receiving/data`, receiveCounts)}
                if (!Window.testMode){ WriteDB(`Main/${month}/${date}/receiving`, receiving)}

            });

            UpdateLog(` [  ${dates.join(', ')}  ] \n imported ${data.length} Receiving rows`);

        });

    
                
        if (fail){
            UpdateLog(`Failed`);
            return;
        }
    }
    const WriteDetamper = ()=>{
        let [dateIndex, employeeIndex, snIndex, partIndex, statusIndex ] = FindIndex(Csv[0], ["Date","Employee","Serial Number","Part","Status"]);
        let indexCheck = [dateIndex, employeeIndex, snIndex, partIndex, statusIndex ];
        if (indexCheck.indexOf(-1) !== -1){ UpdateLog(`Bad Headers`); return; }
        let fail = false;
        ReadDB('MainCounts', (mainCounts)=>{
            let data1 = Csv;
            data1.splice(0, 1);
            let data = [];

            
            data1.map((row)=>{
                if (row.join('').replaceAll(/\s/g,"") === ""){ return; }
                row[dateIndex] = FixDate(row[dateIndex]).toString().firebaseFormat('-');
                if (row[dateIndex].toString().indexOf('Na') !== -1 || row.length !== indexCheck.length){ fail = true; }
                data.push(row);
            });
            if (fail){  UpdateLog('Row Length Mismatch'); return; }
            let dates = GetNewSet(data, dateIndex);
            dates.map((date)=>{
                let month = date.toString().getMonth();
                let rows = [];
                data.map((row)=>{ if (row[dateIndex] === date){ rows.push(row); }});

                let employeeData = {};
                let employeeCounts = {};

                rows.map((row)=>{
                    let employee = row[employeeIndex];
                    let status = (row[statusIndex] || "NA").toString().firebaseFormat();
                    let sn = (row[snIndex] || "NA").toString().firebaseFormat();
                    let part = (row[partIndex] || "NA").toString().firebaseFormat();

                    let isSerialized = CheckSerialized(sn);

                    if (!isSerialized){ sn = part; }
                    if (isSerialized){
                        if (!Window.testMode){
                            WriteDB(`Devices/${sn}/data/detamper/${date}`, {
                                issue : "tamper",
                                result : status,
                            });
                        }
                    }

                    if (employeeData[employee] === undefined){ employeeData[employee] = {}; }
                    if (employeeData[employee][part] === undefined){ employeeData[employee][part] = {}; }
                    if (employeeData[employee][part] === undefined){ employeeData[employee][part] = {}; }
                    if (employeeData[employee][part][sn] === undefined){ employeeData[employee][part][sn] = {
                            issue : "tamper",
                            result : status,
                        }; 
                    }

                    if (employeeCounts[employee] === undefined){ employeeCounts[employee] = {}; }
                    try{ 
                        if (mainCounts[month][date].repair.data[employee].eHours !== undefined){ employeeCounts[employee].eHours = mainCounts[month][date].repair.data[employee].eHours}
                    }catch(e){ }
                    try{ 
                        if (mainCounts[month][date].repair.data[employee].hours !== undefined){ employeeCounts[employee].hours = mainCounts[month][date].repair.data[employee].hours}
                    }catch(e){ }

                    if (employeeCounts[employee][part] === undefined){ employeeCounts[employee][part] = {};}
                    if (employeeCounts[employee][part][status] === undefined){ employeeCounts[employee][part][status] = {};}
                    if (employeeCounts[employee][part][status].qty === undefined){ employeeCounts[employee][part][status].qty = 0;}
                    employeeCounts[employee][part][status].qty += 1;
                });
                console.log(date);
                console.log(employeeData);
                console.log(employeeCounts);
                console.log('\n\n');

                if (!Window.testMode){ WriteDB(`Main/${month}/${date}/detamper`, employeeData)}
                if (!Window.testMode){ WriteDB(`MainCounts/${month}/${date}/detamper/data`, employeeCounts)}
                
            });
            UpdateLog(`[ ${dates.join(', ')} ] \n Detamper - Imported ${data.length} Rows`);
            SetCsv(null);
            fileInputRef.current.value = "";

        });
    }

    const WriteRepair = ()=>{
        let [dateIndex, employeeIndex, snIndex, partIndex, issueIndex, resultIndex ] = FindIndex(Csv[0], ["Date","Employee","Serial Number","Part","Issue","Result"]);
        let indexCheck = [dateIndex, employeeIndex, snIndex, partIndex, issueIndex, resultIndex ];
        if (indexCheck.indexOf(-1) !== -1){ UpdateLog(`Bad Headers`); return; }
        let fail = false;

        ReadDB('MainCounts', (mainCounts)=>{
            let data1 = Csv;
            data1.splice(0, 1);
            let data = [];

            
            data1.map((row)=>{
                if (row.join('').replaceAll(/\s/g,"") === ""){ return; }
                row[dateIndex] = FixDate(row[dateIndex]).toString().firebaseFormat('-');
                if (row[dateIndex].toString().indexOf('Na') !== -1 || row.length !== indexCheck.length){ fail = true; }
                data.push(row);
            });
            if (fail){  UpdateLog('Row Length Mismatch'); return; }
            let dates = GetNewSet(data, dateIndex);
            dates.map((date)=>{
                let month = date.toString().getMonth();
                let rows = [];
                data.map((row)=>{ if (row[dateIndex] === date){ rows.push(row); }});

                let employeeData = {};
                let employeeCounts = {};

                rows.map((row)=>{
                    let employee = row[employeeIndex];
                    let issue = (row[issueIndex] || "NA").toString().firebaseFormat();
                    let result = (row[resultIndex] || "NA").toString().firebaseFormat();
                    let sn = (row[snIndex] || "NA").toString().firebaseFormat();
                    let part = (row[partIndex] || "NA").toString().firebaseFormat();
                    let isSerialized = CheckSerialized(sn);
                    if (!isSerialized){ sn = part; }
                    if (isSerialized){
                        if (!Window.testMode){
                            WriteDB(`Devices/${sn}/data/repair/${date}`, {
                                issue : issue,
                                result : result,
                            });
                        }
                    }

                    if (employeeData[employee] === undefined){ employeeData[employee] = {}; }
                    if (employeeData[employee][part] === undefined){ employeeData[employee][part] = {}; }
                    if (employeeData[employee][part] === undefined){ employeeData[employee][part] = {}; }
                    if (employeeData[employee][part][sn] === undefined){ employeeData[employee][part][sn] = {
                            issue : issue,
                            result : result,
                        }; 
                    }

                    if (employeeCounts[employee] === undefined){ employeeCounts[employee] = {}; }
                    try{ 
                        if (mainCounts[month][date].repair.data[employee].eHours !== undefined){ employeeCounts[employee].eHours = mainCounts[month][date].repair.data[employee].eHours}
                    }catch(e){ }
                    try{ 
                        if (mainCounts[month][date].repair.data[employee].hours !== undefined){ employeeCounts[employee].hours = mainCounts[month][date].repair.data[employee].hours}
                    }catch(e){ }

                    if (employeeCounts[employee][part] === undefined){ employeeCounts[employee][part] = {};}
                    if (employeeCounts[employee][part][issue] === undefined){ employeeCounts[employee][part][issue] = {};}
                    if (employeeCounts[employee][part][issue].qty === undefined){ employeeCounts[employee][part][issue].qty = 0;}
                    employeeCounts[employee][part][issue].qty += 1;
                });
                console.log(date);
                console.log(employeeData);
                console.log(employeeCounts);
                console.log('\n\n');

                if (!Window.testMode){ WriteDB(`Main/${month}/${date}/repair`, employeeData)}
                if (!Window.testMode){ WriteDB(`MainCounts/${month}/${date}/repair/data`, employeeCounts)}
                
            });
            UpdateLog(`[ ${dates.join(', ')} ] \n Repair - Imported ${data.length} Rows`);
            SetCsv(null);
            fileInputRef.current.value = "";
        });


        


        let x = "foo bar";
        let fakeDb = { // month day Repair

            device : {
                data : {
                    repair : {
                        date : {
                            issue : x,
                            result : x,
                        }
                    }
                }
            },


            repair : {
                employee : {
                    part : {
                        sn : {
                            issue : x,
                            result : x,  
                        }
                    }
                }
            },

            repairCount : {
                targets : {
                    part : {
                        qty : x,
                    }
                },
                data : {
                    employee : {
                        eHours  : x,
                        hours : x,
                        part : {
                            issueType : { // dt result , rki keyType
                                qty : x,
                            }

                        }
                    }
                }
            }



        }
    }
    const WriteRki = ()=>{

    }
    const WriteScrap = ()=>{
        let [dateIndex, partIndex, snIndex, qtyIndex] = FindIndex(Csv[0], ["Date","Part Number","Serial Number","Qty"]);
        let indexCheck = [dateIndex, partIndex, snIndex, qtyIndex];
        if (indexCheck.indexOf(-1) !== -1){ return; }

        let data = Csv;
        data.splice(0,1);

        data = data.map((row)=>{ row[dateIndex] = row[dateIndex].toString().firebaseFormat('-'); return row; });

        let dates = GetNewSet(data, dateIndex);
        dates.map((date)=>{
            let month = date.toString().getMonth();
            let rows = [];
            let scrap = {};
            let scrapCount = {};

            data.map((row)=>{
                if (row[dateIndex] === date){ rows.push(row); }
            });

            rows.map((row)=>{
                let part = row[partIndex].firebaseFormat();
                let sn = row[snIndex].firebaseFormat();
                let qty = (row[qtyIndex] || "").toString().quantity();
                let isSerialized = CheckSerialized(sn, part);
                if (!isSerialized){ sn = part; }

                if (isSerialized){
                    if (!Window.testMode){
                        WriteDB(`Devices/${sn}/data/scrap/${date}`, {
                            qty : qty,
                        });
                    }
                }

                if (scrap[sn] === undefined){ scrap[sn] = { part : part, qty : 0 }; }
                scrap[sn].qty += qty;

                if (scrapCount[part] === undefined){ scrapCount[part] = { qty : 0 }; }
                scrapCount[part].qty += qty;
            });

            if (!Window.testMode){ WriteDB(`Main/${month}/${date}/Scrap`, scrap ) }
            if (!Window.testMode){ WriteDB(`MainCounts/${month}/${date}/Scrap`, scrapCount ) }
        });

        UpdateLog(` [ ${dates.join(', ')}  ] \n Scrap Updated`);
        SetCsv(null);
        fileInputRef.current.value = "";
    }

    const WriteTransfers = ()=>{
        let [dateIndex, partIndex, snIndex, qtyIndex, fromLoc, toLoc] = FindIndex(Csv[0], ["Date","Part Number","Serial Number","Qty","From","To"]);
        let indexCheck = [dateIndex, partIndex, snIndex, qtyIndex];
        if (indexCheck.indexOf(-1) !== -1){ return; }

        let data = Csv;
        data.splice(0,1);
        data = data.map((row)=>{ row[dateIndex] = row[dateIndex].toString().firebaseFormat('-'); return row;});
        let dates = GetNewSet(data, dateIndex);
        dates.map((date)=>{

            let month = date.toString().getMonth();
            let rows = [];
            let transfers = {}
            let transferCounts = {};

            data.map((row)=>{
                if (row[dateIndex] === date){ rows.push(row); }
            });

            rows.map((row)=>{
                let from = row[fromLoc].firebaseFormat().toString().toUpperCase();
                let to = row[toLoc].firebaseFormat().toString().toUpperCase();
                if (from === "DLC"){ from = "PLC"; }
                if (to === "DLC"){ to = "PLC"; }
                let part = row[partIndex].firebaseFormat();
                let sn = row[snIndex].firebaseFormat();
                let qty = (row[qtyIndex] || "").toString().quantity();
                let isSerialized = CheckSerialized(sn, part);

                if (!isSerialized){ sn = part; }
                
                if (isSerialized === true){
                    if (!Window.testMode){
                        WriteDB(`Devices/${sn}/data/transfers/${date}`, {
                            fromLoc : from,
                            toLoc : to,
                            qty : qty,
                        });
                    }
                }

                if (transfers[from] === undefined){ transfers[from] = {}; }
                if (transfers[from][to] === undefined){ transfers[from][to] = {}}
                if (transfers[from][to][sn] === undefined){ transfers[from][to][sn] = {
                    part : part,
                    qty : 0,
                }}
                transfers[from][to][sn].qty += qty;

                if (transferCounts[from] === undefined){ transferCounts[from] = {}}
                if (transferCounts[from][to] === undefined){ transferCounts[from][to] = {}}
                if (transferCounts[from][to][part] === undefined){ transferCounts[from][to][part] = { qty : 0}; }
                transferCounts[from][to][part].qty += qty;

            });
            if (!Window.testMode){ WriteDB(`Main/${month}/${date}/transfers`, transfers); }
            if (!Window.testMode){ WriteDB(`MainCounts/${month}/${date}/transfers`, transferCounts); }
            console.log(date);
            console.log(transferCounts);
            console.log('\n');
        });


        UpdateLog(` [ ${dates.join(', ')} ] \n imported ${Csv.length} Transfer rows`);
        SetCsv(null);
        fileInputRef.current.value = "";
    }
    const WriteInventory = ()=>{
        let [dateIndex, partIndex, snIndex, locIndex, areaIndex, rackIndex, shelfIndex, qtyIndex] = FindIndex(Csv[0], ["Date","Part Number","Serial Number","Location","Area","Rack","Shelf","Qty"]);
        let indexCheck = [dateIndex, partIndex, snIndex, locIndex, areaIndex, rackIndex, shelfIndex, qtyIndex];
        if (indexCheck.indexOf(-1) !== -1){ return; }
        let data = Csv;
        data.splice(0,1);
        let dates = GetNewSet(data, dateIndex);
        let locations = GetNewSet(data, locIndex);

        dates.map((date)=>{
            let month = date.toString().getMonth();
            let inv = {};
            let invCount = {};
            locations.map((location)=>{
                let locData = {};
                let locCounts = {};
                let locRows = [];
                data.map((row)=>{
                    if (row[dateIndex] === date && row[locIndex] === location){
                        locRows.push(row);
                    }
                });
                for(let i = 0; i < locRows.length; i++){
                    let row = locRows[i];
                    let part = row[partIndex].firebaseFormat();
                    let sn = row[snIndex].firebaseFormat();
                    let area = row[areaIndex].firebaseFormat();
                    let rack = row[rackIndex].firebaseFormat();
                    let shelf = row[shelfIndex].firebaseFormat();
                    let qty = (row[qtyIndex] || "").toString().quantity();
                    if (!locData[area]){ locData[area] = {}; locCounts[area] = {}}
                    if (!locData[area][rack]){ locData[area][rack] = {}; locCounts[area][rack] = {}}
                    if (!locData[area][rack][shelf]){ locData[area][rack][shelf] = {}; locCounts[area][rack][shelf] = {}}
                    if (!locData[area][rack][shelf][sn]){ 
                        locData[area][rack][shelf][sn] = {
                            part : part,
                            qty : 0,
                        };
                    }
                    if (!locCounts[area][rack][shelf][part]){
                        locCounts[area][rack][shelf][part] = { qty : 0 };
                    }

                    locData[area][rack][shelf][sn].qty += qty;
                    locCounts[area][rack][shelf][part].qty += qty;
                }
                inv[location] = locData;
                invCount[location] = locCounts;
            });
            if (!Window.testMode){ WriteDB(`Main/${month}/${date.toString().firebaseFormat('-')}/inventory`, inv); }
            if (!Window.testMode){ WriteDB(`MainCounts/${month}/${date.toString().firebaseFormat('-')}/inventory`, invCount); }
            console.log(invCount);
            

        });
        UpdateLog(`  [  ${dates.join(", ")}  ] \n Inventory Imported`);
        SetCsv(null);
        fileInputRef.current.value = "";
        
    }
    
    const WriteOp = ()=>{
        let write = {};
        let rows = [];
        let data = Csv;
        data.splice(0,1);
        data.map((row)=>{
            write[row[1]] = {
                part : row[0],
                location : 'NA-NA-NA',
                qty : parseInt(row[3]),
            }
        });

        WriteDB('Inventory/scans', write);

    }
    
    const fileInputRef = useRef();
    return (
        <div className = "absolute inset-0 flex flex-row">

            <div className = "w-[12%] devLeft relative min-w-[180px]">
                <div className = "w-[100%] h-[90px] relative">
                    <DateSelector update = { setDate } />
                </div>
                
                <DepartmentSelector update = { setDept } opts = {['Inventory','Scrap','Transfers']}/>
                <div className = "absolute bottom-[10px] left-0 right-0">
                    <div className = "m-2 p-2 dropdown mb-3 noSelect flex justify-center" style = {{ background : "rgb(50, 97, 185, 0.2)", zIndex : '50'}} >
                        Read
                    </div>
                    <div className = "m-2 p-2 dropdown mb-3 noSelect flex justify-center" style = {{ background : "rgb(50, 97, 185, 0.2)", zIndex : '50'}}  onClick = {()=>{ToggleImports(); }}>
                        Import
                    </div>
                    <div className = "m-2 p-2 dropdown mb-3 noSelect flex justify-center" style = {{ background : "#ffffff05", zIndex : '50'}} >
                        Download
                    </div>
                    <div className = "m-2 mb-1 p-2 dropdown noSelect flex justify-center" style = {{ background : "#ffffff05", zIndex : '50'}} >
                        Write
                    </div>
                </div>
            </div>

            <div className = "w-[88%] h-[100%] devRight noSelect flex flex-col relative">
                <DevTable department = { selectedDept } headers = {headers} />
            </div>
            <div className = {`blurField absolute inset-0 ${importActive}`} onClick = {()=>{ToggleImports()}}>
                <div className = "importBox darkBg flex flex-row" onClick = {(e)=>{ e.stopPropagation(); }}>
                    <div className = "importLeft flex flex-col w-[35%] p-2 relative">
                        <DepartmentSelector update = {setImportDept} opts = {['Rki','Inventory','Scrap','Transfers', 'writeOp']}/>
                        <input ref = {fileInputRef} className = "importFile noSelect p-4" type = "file" onChange = {(e)=>{ UploadFile(e); }}>
                            
                        </input>
                        <button onMouseDown = {(e)=>{ e.target.style.bottom = "calc(0.5em - 2px)"; setTimeout(()=>{ e.target.style.bottom = "0.5em"; }, 30);
                        }} onClick = {HandleWrite} className = "m-2 p-2 dropdown mb-3 noSelect flex justify-center absolute writeButton" style = {{ position : 'absolute', width : "calc(100% - 2em)", bottom : '0.5em',background : "rgb(50, 97, 185, 0.2)", zIndex : '50'}} >
                            Write
                        </button>
                    </div>
                    <div className = "importConsole flex-col flex w-[65%] m-1 relative">
                        <div className = "consoleLogs flex-col flex absolute inset-[0.5em]">

                            <div className = "consoleRows flex-col flex">
                                {
                                    logs.split('\n').map((line, i)=>{
                                        return <div className = 'consoleRow noSelect' key = {`consoleRow${i}`}>{line}</div>
                                    })
                                }
                                <div ref = {logRef}></div>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    )
}


function FindIndex(row, headers){
    let values = [];
    // eslint-disable-next-line
    headers.map((key)=>{
        let index = -1;
        for(let i = 0; i < row.length; i++){
            let val = row[i];
            if (key.toString().toLowerCase() === val.toString().toLowerCase()){
                index = i;
                break;
            }
        }
        values.push(index);
    });
    return values;
}

function GetNewSet(array, index){
    return Array.from(new Set(array.map((row)=>{ return row[index] || "" })));
}

String.prototype.quantity = function(def){
    if (def === undefined){ def = 1;}
    return (isNaN(this) ? def : parseInt(this));
}

String.prototype.firebaseFormat = function(char){
    if (!char){ char = ""; }
    // eslint-disable-next-line
    let str = (this || "").toString().replaceAll(/[\/\\#$."\[\]]/g, char);
    str = str.toString().replaceAll(/[\s\t]/g,' ').slice(0, 450);
    return str;
}

String.prototype.getMonth = function(){
    return this.toString().slice(0,2)+'-'+this.toString().slice(-2);
}
export default Dev;
