import React, { useEffect, useState } from 'react';
import { DepartmentSelector, GetDataCounts, GetLastDay } from '../imports';

function CalCounters({ dataset, date, setDept, selectedDept }) {
    const [data, setData] = useState({});
    const [selectedDate, setDate] = useState(date);
    const selectedPart = "PSTA3-000-R";

    const [values, setValues] = useState([]);

    useEffect(()=>{
        setDate(date);
        setData(dataset);
    },[dataset, date]);

    useEffect(()=>{
        let date = selectedDate.toString().replaceAll('-','/').split("/");
        date = `${date[0]}-${date[date.length - 1]}`;
        let lastDate = GetLastDay(`${date.split('-')[0]}-01-${date.split('-')[1]}`);
        lastDate = lastDate.toString().replaceAll('-','/').split('/');
        lastDate = `${lastDate[0]}-${lastDate[lastDate.length - 1]}`;

        if (Window.counts[lastDate] === undefined){
            setTimeout(()=>{
                GetValues(data);
            }, 150);
        }else{
            GetValues(data);
        }

        
    }, [data, selectedDate, selectedDept]);


    const GetValues = (monthCounts)=>{
        let date = selectedDate.toString().replaceAll('-','/').split("/");
        date = `${date[0]}-${date[date.length - 1]}`;
        let currentCounts = GetCounterData(monthCounts, date, selectedDept, selectedPart);
        let lastDate = GetLastDay(`${date.split('-')[0]}-01-${date.split('-')[1]}`);
        lastDate = lastDate.toString().replaceAll('-','/').split('/');
        lastDate = `${lastDate[0]}-${lastDate[lastDate.length - 1]}`;
        let lmCounts = GetCounterData(monthCounts, lastDate, selectedDept, selectedPart);
        setValues([
            [ [currentCounts.forecasted.employees,lmCounts.forecasted.employees], [currentCounts.forecasted.hours, lmCounts.forecasted.hours], [currentCounts.forecasted.units, lmCounts.forecasted.units] ],
            [ [currentCounts.actual.employees,lmCounts.actual.employees], [currentCounts.actual.hours,lmCounts.actual.hours], [currentCounts.actual.units,lmCounts.actual.units] ],
            [ [currentCounts.totalTarget,lmCounts.totalTarget], [currentCounts.totalCompleted,lmCounts.totalCompleted], [currentCounts.totalHours,lmCounts.totalHours],[ `${currentCounts.targetMet} / ${currentCounts.targetCount}`,`${lmCounts.targetMet} / ${lmCounts.targetCount}`]]
        ]);
    }

    // avg employees
    // avg hours
    // avg target
    // avg units

    // target met : x / x
    // total target : x, 
    // total completed : x,
    

  return (
    <div className = "absolute inset-0 flex flex-col">
      <DepartmentSelector update = {setDept} opts = { ['RKI','Cleaning','Firmware','Diagnostics']} />
      <div className = "header w-[100%] flex items-center justify-center greyFont text-sm font-medium noSelect mb-2">Average</div>
      <div className = "h-[100%] w-[100%] flex flex-col justify-between">
        <div className = "calCounterRow" >
            <div className = "noSelect greyFont text-sm font-medium">Employees</div>
            <div className = "noSelect text-sm font-medium">{(( Math.round((values[1]?.[0]?.[0]) / 0.25) * 0.25 ) || 0)}</div>
        </div>
        <div className = "calCounterRow">
            <div className = "noSelect greyFont text-sm font-medium">Hours</div>
            <div className = "noSelect text-sm font-medium">{(( Math.round((values[1]?.[1]?.[0]) / 0.25) * 0.25) || 0 )}</div>
        </div>
        <div className = "calCounterRow">
            <div className = "noSelect greyFont text-sm font-medium">Target</div>
            <div className = "noSelect text-sm font-medium">{(( Math.round((values[0]?.[2]?.[0]) / 0.25) * 0.25 ) || 0)}</div>
        </div>
        <div className = "calCounterRow">
            <div className = "noSelect greyFont text-sm font-medium">Units</div>
            <div className = "noSelect text-sm font-medium">{(( Math.round((values[1]?.[2]?.[0]) / 0.25) * 0.25 ) || 0)}</div>
        </div>

        <div className = "calCounterRow" style = {{ gridTemplateColumns : "2fr 2fr"}}>
            <div className = "noSelect greyFont text-sm font-medium">Total Target</div>
            <div className = "noSelect text-sm font-medium">{(( Math.round((values[2]?.[0]?.[0]) / 0.25) * 0.25 ) || 0)}</div>
        </div>

        <div className = "calCounterRow" style = {{ gridTemplateColumns : "2fr 2fr"}}>
            <div className = "noSelect greyFont text-sm font-medium">Total Untis</div>
            <div className = "noSelect text-sm font-medium">{(( Math.round((values[2]?.[1]?.[0]) / 0.25) * 0.25 ) || 0)}</div>
        </div>

        <div className = "calCounterRow" style = {{ gridTemplateColumns : "2fr 2fr"}}>
            <div className = "noSelect greyFont text-sm font-medium">Target Met</div>
            <div className = "noSelect text-sm font-medium">{ values[2]?.[3]?.[0]}</div>
        </div>



      </div>
    </div>
  )
}

export default CalCounters;


function GetCounterData(data, date, dept, part){
    dept = dept.toString().toLowerCase();
    let mainCounts = GetDataCounts(data[date])[dept];

    let counts = {
        forecasted : { // Averages
            employees : 0,
            hours : 0,
            units : 0,
        },
        actual : {
            employees : 0,
            hours : 0,
            units : 0,
        },

        totalTarget : 0,
        totalCompleted : 0,
        comletion : 0,
        totalHours : 0,

        targetMet : 0,
        targetCount : 0, // days
    }
    if (Object.keys(mainCounts).length === 0){ return counts; }
    Object.keys(mainCounts).map((date)=>{
        if (Object.keys(mainCounts[date]).length === 0){ return; }
        if (mainCounts[date][part] === undefined && mainCounts[date][part.toString().replace('-R','')]){ return; }
        
        let pData = mainCounts[date][part];
        let target = (pData.target || 0);
        let count = (pData.count || 0);
        let employees = (mainCounts[date].employees || 0);
        let eEmployees = (mainCounts[date].eEmployees || 0);
        let hours = (mainCounts[date].hours || 0);
        let eHours = (mainCounts[date].eHours || 0);

        if (mainCounts[date][part.toString().replace('-R','')]){
            let nPData = mainCounts[date][part.toString().replace('-R','')];
            target += (nPData.target || 0);
            count += (nPData.count || 0);
        }

        

        if (count > 0){
            if (target <= count){
                counts.targetMet += 1;
            }
            
            counts.targetCount += 1;
        }

        counts.forecasted.employees += eEmployees;
        counts.actual.employees += employees;

        counts.forecasted.hours += eHours;
        counts.actual.hours += hours;

        counts.forecasted.units += target;
        counts.actual.units += count;

        counts.totalHours += hours;
        counts.totalCompleted += count;
        counts.totalTarget += target;

    });

    counts.forecasted.employees = ((counts.forecasted.employees / counts.targetCount) ?? 0);
    counts.actual.employees = ((counts.actual.employees / counts.targetCount) ?? 0);
    counts.forecasted.units = ((counts.forecasted.units / counts.targetCount) ?? 0);
    counts.actual.units = ((counts.actual.units / counts.targetCount) ?? 0);
    counts.forecasted.hours = ((counts.forecasted.hours / counts.targetCount) ?? 0);
    counts.actual.hours = ((counts.actual.hours / counts.targetCount) ?? 0);


    return counts;
}
