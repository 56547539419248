import React, {useEffect, useState, useRef, createRef } from 'react'
import {  GetCurrentDate, WatchDB, ReadDB,GetDeptVals } from '../imports';
Window.counts = {};

function DepartmentOverview({ date }) {
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const departments = ['Shipping','Receiving','Detamper','Repair','Diagnostics','Cleaning','Firmware','Rki'];
    const [departmentCounts, SetDepartmentCounts] = useState({});
    const [month, SetMonth] = useState("");
    const selectedPart = "PSTA3-000-R";
    useEffect(()=>{
        SetData(date);
        let month = months[parseInt(date.toString().slice(0,2)) - 1];
        SetMonth(month);
    },[date]);

    const SetData = (date)=>{
        date = date.toString().slice(0,2)+'-'+date.toString().slice(-2);
        if (Window.counts[date] === undefined){
            setTimeout(()=>{
                SetData(date);
            }, 10);
            return;
        }
        SetCounts(Window.counts[date]);
    }

    const DRefs = [[useRef(), useRef()],[useRef(),useRef()],[useRef(), useRef()],[useRef(),useRef()],[useRef(), useRef()],[useRef(),useRef()],[useRef(), useRef()],[useRef(),useRef()]]; // create ref in loop bugged out

    const SetCounts = (data)=>{
        
        [...departments].sort().map((dept, i)=>{
            try{ DRefs[i][0].current.style.width = `0%`; }catch(e){ }
            try{ DRefs[i][1].current.style.width = `0%` }catch(e){ }
        });
        if (!data){  return; }
        
        let counts = {};
        departments.map((dept)=>{
            dept = dept.toString().toLowerCase();
            counts[dept] = {};

            Object.keys(data).map((date)=>{
                if (data[date][dept] !== undefined){
                    switch (dept){
                        case 'shipping' :

                        case 'receiving' :
                            if (data[date][dept].targets !== undefined ){
                                Object.keys(data[date][dept].targets).map((f)=>{
                                    Object.keys(data[date][dept].targets[f]).map((p)=>{
                                        if (counts[dept][p] === undefined){ counts[dept][p] = {}; }
                                        if (counts[dept][p].target === undefined){ counts[dept][p].target = 0; }
                                        counts[dept][p].target += data[date][dept].targets[f][p].qty;
                                    });
                                });
                            }
                            if (data[date][dept].data !== undefined){
                                Object.keys(data[date][dept].data).map((employee)=>{
                                    Object.keys(data[date][dept].data[employee]).map((franchise)=>{
                                        if (ignore.indexOf(franchise) !== -1){ return; }
                                        Object.keys(data[date][dept].data[employee][franchise]).map((part)=>{
                                            if (counts[dept][part] === undefined){ counts[dept][part] = {}; }
                                            if (counts[dept][part].count === undefined){ counts[dept][part].count = 0; }
                                            Object.keys(data[date][dept].data[employee][franchise][part]).map((status)=>{
                                                counts[dept][part].count += data[date][dept].data[employee][franchise][part][status].qty;
                                            });
                                            
                                        });
                                    });
                                });
                            }

                            break;

                        case 'rki' :
                        
                        case 'detamper' :

                        case 'cleaning' :

                        case 'firmware' :

                        case 'diagnostics' :

                        case 'repair' :
                            if (data[date][dept].targets !== undefined ){
                                Object.keys(data[date][dept].targets).map((p)=>{
                                    if (counts[dept][p] === undefined){ counts[dept][p] = {}; }
                                    if (counts[dept][p].target === undefined){ counts[dept][p].target = 0; }
                                    counts[dept][p].target += data[date][dept].targets[p].qty;
                                });
                            }
                            if (data[date][dept].data !== undefined){
                                Object.keys(data[date][dept].data).map((employee)=>{
                                    Object.keys(data[date][dept].data[employee]).map((part)=>{
                                        if (ignore.indexOf(part) !== -1){ return; }
                                            if (counts[dept][part] === undefined){ counts[dept][part] = {}; }
                                            if (counts[dept][part].count === undefined){ counts[dept][part].count = 0; }
                                            Object.keys(data[date][dept].data[employee][part]).map((status)=>{
                                            counts[dept][part].count += data[date][dept].data[employee][part][status].qty;
                                        });
                                    });
                                });
                            }
                            
                    }
                }
            });
        });

        setTimeout(()=>{
            departments.map((dept, i)=>{
                let widths = GetDeptVals(dept.toString().toLowerCase(), selectedPart, counts);
                try{
                    DRefs[i][0].current.style.width = `calc(${widths[0].toString()}% - 12px)`;
                    DRefs[i][1].current.style.width = `calc(${widths[0].toString()}% - 12px)`;
                }catch(e){
                    try{ DRefs[i][0].current.style.width = `0%`; }catch(e){ }
                    try{ DRefs[i][1].current.style.width = `0%` }catch(e){ }
                }
                
            });
        }, 0);
        

        SetDepartmentCounts(counts);
    }

    

    let x = "";
    let test = {
        department : {
            target : x,
            count : x,
            weight : x,
        }
    }

    
    return (
        <div className = "absolute inset-1 deptOverview">
            <div className = "absolute top-[0px] left-0 right-[4px] bottom-0 flex flex-col yScroll">
                {
                    Object.keys(departmentCounts).map((dept, i)=>{

                        return (
                            <div className = "deptRow items-center justify-end flex relative" key = {dept.toString()+'countRow'} style = {{ padding : "6px", paddingTop : '12px', paddingBottom: '12px', width : "calc(100% - 12px)"}}>
                                <div className = "deptRow0">
                                    <div className = "noSelect text">{dept}</div>
                                </div>
                                
                                <div ref = {DRefs[i][1]} className = "targetBar absolute right-[0px] top-[6px] bottom-[6px]" >

                                </div>
                                <div ref = {DRefs[i][0]} className = "progressBar absolute right-[0px] top-[6px] bottom-[6px]" >
                                    
                                </div>
                            </div>
                        )
                    })
                }            
            </div>
        </div>
    )
}



export default DepartmentOverview;
const ignore = ['hours','eHours'];


