import React, { useState }from 'react';
import { TopBar, SideBar } from '../imports.js';

function Home() {
  

  return (
    <div className = "absolute inset-0">
      wow home page
    </div>
  )
}

export default Home;
