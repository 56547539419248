import { ref, get, set, child, onValue} from "firebase/database";
export { default as Home } from "./components/home";
export { default as Login } from "./components/login";
export { default as TopBar } from "./components/topbar";
export { default as SideBar } from "./components/sidebar";
export { default as Repair } from "./components/repair";
export { default as Shipping } from "./components/shipping";
export { default as Receiving } from "./components/receiving";
export { default as Detamper } from "./components/detamper";
export { default as Inventory } from "./components/inventory";
export { default as Dev } from "./components/dev";
export { default as DevTable } from "./components/devTable";
export { default as Admin } from "./components/admin";
export { default as DateSelector } from "./components/selectors/dateSelector";
export { default as DepartmentSelector } from "./components/selectors/departmentSelector"; 
export { default as DepartmentOverview } from "./components/departmentOverview";
export { default as DepartmentAreaChart } from "./components/departmentAreaChart";
export { default as LineChart } from "./components/Charts/lineChart";
export { default as AdminCalendar } from "./components/adminCalendar";
export { default as CalCounters } from "./components/calCounters"; 
export { default as InventoryOverview } from "./components/inventoryOverview";

let paths = [];

export async function ReadDB(path, callback){
    
    if (paths.indexOf(path) !== -1){
        return;
    }
    paths.push(path);
    setTimeout(()=>{
        paths.splice(path.indexOf(path), 1);
    },200);

    console.log(`Read :  ${path} \n\n`);
    await get(child(ref(Window.database), path)).then((data)=>{
        if (!data.exists()){ data = undefined; }else{ data = data.val(); }
        if (callback){
            return callback(data);
        }
        return data;
    });
}

export function WriteDB(path, value){
    set(ref(Window.database, path), value);
}

Window.dbListeners = {};
export async function WatchDB(path, callback){
    if (Window.dbListeners[path]){
        try{
            let listener = Window.dbListeners[path]; // remove listener
            listener();
        }catch(e){ console.log(e); }
        delete Window.dbListeners[path];
    }
    Window.dbListeners[path] = onValue(ref(Window.database, path), (data)=>{
        if (!data.exists()){ data = undefined; }else{ data = data.val(); }
        return callback(data);
    });
}


export async function VerifyLogin(user){
    if (!user){ return false; }
    let uid = user.uid.toString();
    let validLogin;
    await ReadDB(`Users/${uid}`, (data)=>{
        if (!data){ validLogin = false; return;}
        Window.userData = data;
        validLogin = true;
    });
    return validLogin;
}

export function GetFranchise(franchiseName){
    let franchise;
    franchiseName = franchiseName.toString().toLowerCase().replaceAll(/\s+/g," ").trim();
    // eslint-disable-next-line
    Object.keys(franchises).map((key)=>{
        if (franchiseName.indexOf(key) !== -1){
            franchise = franchises[key];
        }
    });

    return franchise;
}



const franchises = {
    'brinker' : 'Brinker',
    'red lobster' : 'Red Lobster',
    'red lobster canada' : 'Red Lobster Canada',
    'aag' : 'AAG',
    'rmh' : 'RMH',
    'tl cannon' : "TL Cannon",
    'evergreen' : 'Evergreen',
    'apple by the bay' : 'Apple Cal',
    'apple cal' : 'Apple Cal',
    'valenti' : 'Valenti',
    'bloomin' : 'Outback',
    'bbq holdings' : "Famous Dave's",
    "outback" : "Outback",
    "out west" : "Outback",
    "sticky" : "Sushi Roll",
    "casa de" : "Casa De Fruta",
    "famous dave" : "Famous Dave's",
    "apple-metro" : "Apple-Metro",
    "apple metro" : "Apple-Metro",
    "shoney" : "Shoney's",
    "a.n.a" : "A.N.A Inc",
    'denny' : "Denny's",
    "doherty" : "Doherty Inc",
    "el apple" : "El Apple",
    "rose casual" : "Rose Casual Dining",
    "stanley" : "Stanley's Sports Bar",
    "sun capital" : "Sun Capital",
    "tokio" : "Tokio Inc",
    "apple texas" : "Apple Texas",
    "qdi" : "QDI",
    "big river" : "Big River",
    "johnny rocket" : "Johnny Rockets",
    "checkers and" : "Checkers",
    "asi" : "ASI",
    "star" : "StarCorp"
}


export function ConvertToDate(value){
    let year = "20"+value.toString().slice(-2);
    year = parseInt(year);
    let month = parseInt(value.toString().slice(0,2));
    month = month - 1;
    let day = value.toString().slice(3,5);
    day = parseInt(day);

    return new Date(year, month, day);
}

export function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}
  
export function formatDate(date) {
    return [
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
        date.getFullYear().toString().slice(-2)
    ].join('/');
}

export function GetCurrentDate(){
    return formatDate(new Date());
}

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday','Saturday'];
const months = ["January", 'February', 'March', 'April', 'May', 'June','July','August','September', 'October', 'November', 'December'];
export function GetLongDate(date){
    date = date.toString().replaceAll('-','/');
    date = ConvertToDate(date);
    let weekday = weekdays[date.getDay()];
    let month = months[date.getMonth()];
    date = formatDate(date);
    let thangs = ['th','st','nd','rd','th','th','th','th','th','th'];
    if (parseInt(date.toString().slice(3,5)) > 9 && parseInt(date.toString().slice(3,5)) < 20){ thangs = ['th','th','th','th','th','th','th','th','th','th'] }

    let day = parseInt(date.toString().split('/')[1]).toString();

    return `${weekday},/${month} ${day}${thangs[parseInt(day.toString().slice(-1))]}`;

}


export function FixDate(date){
    date = ConvertToDate(date);
    date = formatDate(date);
    return date;
}

export function GetLastDayInMonth(date){
    let month = parseInt(date.toString().slice(0,2));
    let year = parseInt("20"+date.toString().slice(-2));

    let d = new Date(year, month, 0);
    d = formatDate(d);

    return d;
}



export function GetDayOfWeek(date){
    let daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday","Saturday"];
    date = ConvertToDate(date).getDay();
    return daysOfWeek[date];
}

export function GetNextDay(date){
    date = ConvertToDate(date);
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    date = formatDate(date);

    return date;
}

export function GetLastDay(date){
    date = ConvertToDate(date);
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
    date = formatDate(date);

    return date;
}


export function sleepTime(ms) {
    return new Promise(resolveFunc => setTimeout(resolveFunc, ms));
}
  
export async function Sleep(ms) {
    for (let i = 0; i < 1; ++i) {
        await sleepTime(ms);
    }
}




export function SetGlobals(){
    WatchDB('Parts', (data)=>{
        Window.parts = data;
    });
    let today =GetCurrentDate();
    let month = today.toString().slice(0,2)+'-'+today.toString().slice(-2);
}

export function DownloadCSV({ data, title }){
    console.log(data);
    let csv = "";
    data.map((row)=>{
        try{ row = row.map((val)=>{ return val.toString().replaceAll(',',''); }); }catch(e){ }
        csv += row.join(',');
        csv += "\n";
    });

    let newLink = document.createElement('a');
    newLink.href = "data:text/csv;charset=utf-8,"+encodeURI(csv);
    if (!title){ title = "data";}
    title = title.toString()+'.csv';
    newLink.download = title.toString();
    newLink.click();
}

export function IdPart(sn){
    if(!sn){ return "NA"; }
    let part = "NA";
    try{
        sn = sn.toString().toUpperCase();
        let opts = Object.keys(Window.parts);
        for(let i = 0; i < opts.length; i++){
            let p = opts[i];
            if (p === sn){
                part = p;
                break;
            }

            let ids = Window.parts[p].id;
            for(let j = 0; j < ids.length; j++){
                let idObj = ids[j];
                let method = idObj.type;
                let idVal = idObj.id;
                let len = idObj.len || 0;
                if (method === "Starts With"){
                    if (sn.toString().slice(0,idVal.length) === idVal){
                        if (len > 0){
                            if (sn.toString().length === len){
                                part = p;
                                break;
                            }
                        }else{ part = p; break; }
                    }
                }
                if(method === "Ends With"){
                    if (sn.toString().slice(sn.length - idVal.length,sn.length) === idVal){
                        if (len > 0){
                            if (sn.toString().length === len){
                                part = p;
                                break;
                            }
                        }else{ part = p; break; }
                    }
                }
                if (method === "Contains"){
                    if (sn.toString().indexOf(idVal) !== -1){
                        if (len > 0){
                            if (sn.length === len){
                                part = p;
                                break;
                            }
                        }else{
                            part = p; break;
                        }
                    }
                }
            }
        }

        
    }catch(e){ }
    return part;
}

export function CheckSerialized(sn, part){
    try{
        if (Window.parts[IdPart(sn)].serialized === false){
            return false;
        }
    }catch(e){ }
    try{
        if (Window.parts[part].serialized === false){
            return false;
        }
    }catch(e){ }

    if (IdPart(sn) === "NA"){
        return false;
    }

    return true;
}

export function GetDeptVals(dept, part, counts){
    let highest = 0;  // target width / count width 
    let qty = 0;
    let target = 0;

    Object.keys(counts).map((d)=>{
        let dCounts = counts[d];
        if (dCounts[part]?.target > highest){ highest = dCounts[part].target }
        if (dCounts[part]?.count > highest){ highest = dCounts[part].count }
        if (d === dept){
            qty = (dCounts[part]?.count || 0);
            target = (dCounts[part]?.target || 0);
        }
    });

    let width = 0;
    let targetWidth = 0;

    if (qty !== 0){ width = qty / highest; }
    if (target !== 0){ targetWidth = target / highest; }

    return [width*100, targetWidth*100];
}

const departments = ['Shipping','Receiving','Detamper','Repair','Diagnostics','Cleaning','Firmware','Rki'];
const ignore = ['hours','eHours'];

export function GetDataCounts(data){
    let counts = {};

    departments.map((dept)=>{
        dept = dept.toString().toLowerCase();
        counts[dept] = {};

        if (!data){ return; }
        Object.keys(data).map((date)=>{
            if (counts[dept][date] === undefined){ counts[dept][date] = {}; }
            if (data[date][dept] !== undefined){
                switch (dept){
                    case 'shipping' :

                    case 'receiving' :
                        if (data[date][dept].targets !== undefined ){
                            Object.keys(data[date][dept].targets).map((f)=>{
                                Object.keys(data[date][dept].targets[f]).map((p)=>{
                                    if (counts[dept][date][p] === undefined){ counts[dept][date][p] = {}; }
                                    if (counts[dept][date][p].target === undefined){ counts[dept][date][p].target = 0; }
                                    counts[dept][date][p].target += data[date][dept].targets[f][p].qty;
                                });
                            });
                        }
                        if (data[date][dept].data !== undefined){
                            Object.keys(data[date][dept].data).map((employee)=>{
                                Object.keys(data[date][dept].data[employee]).map((franchise)=>{
                                    
                                    if (ignore.indexOf(franchise) !== -1){ 
                                        if (franchise === "eHours"){
                                            if (counts[dept][date].eHours === undefined){ counts[dept][date].eHours = 0; }
                                            counts[dept][date].eHours += (data[date][dept].data[employee].eHours || 0);
                                        }
                                        if (franchise === "hours"){
                                            if (counts[dept][date].hours === undefined){ counts[dept][date].hours = 0; }
                                            counts[dept][date].hours += (data[date][dept].data[employee].hours || 0);
                                        }
                                        return; 
                                    }

                                    Object.keys(data[date][dept].data[employee][franchise]).map((part)=>{
                                        if (counts[dept][date][part] === undefined){ counts[dept][date][part] = {}; }
                                        if (counts[dept][date][part].count === undefined){ counts[dept][date][part].count = 0; }
                                        Object.keys(data[date][dept].data[employee][franchise][part]).map((status)=>{
                                            counts[dept][date][part].count += data[date][dept].data[employee][franchise][part][status].qty;
                                        });
                                        
                                    });
                                });
                                if (counts[dept][date].employees === undefined){ counts[dept][date].employees = 0; }
                                counts[dept][date].employees += 1; //
                            });
                        }

                        break;

                    case 'rki' :
                    
                    case 'detamper' :

                    case 'cleaning' :

                    case 'firmware' :

                    case 'diagnostics' :

                    case 'repair' :
                        if (data[date][dept].targets !== undefined ){
                            Object.keys(data[date][dept].targets).map((p)=>{
                                if (counts[dept][date][p] === undefined){ counts[dept][date][p] = {}; }
                                if (counts[dept][date][p].target === undefined){ counts[dept][date][p].target = 0; }
                                counts[dept][date][p].target += data[date][dept].targets[p].qty;
                            });
                        }
                        if (data[date][dept].data !== undefined){
                            Object.keys(data[date][dept].data).map((employee)=>{
                                Object.keys(data[date][dept].data[employee]).map((part)=>{
                                    if (ignore.indexOf(part) !== -1){ 
                                        if (part === "eHours"){
                                            if (counts[dept][date].eHours === undefined){ counts[dept][date].eHours = 0; }
                                            counts[dept][date].eHours += (data[date][dept].data[employee].eHours || 0);
                                        }
                                        if (part === "hours"){
                                            if (counts[dept][date].hours === undefined){ counts[dept][date].hours = 0; }
                                            counts[dept][date].hours += (data[date][dept].data[employee].hours || 0);
                                        }
                                        return; 
                                    }
                                    if (counts[dept][date][part] === undefined){ counts[dept][date][part] = {}; }
                                    if (counts[dept][date][part].count === undefined){ counts[dept][date][part].count = 0; }
                                    Object.keys(data[date][dept].data[employee][part]).map((status)=>{
                                    counts[dept][date][part].count += data[date][dept].data[employee][part][status].qty;
                                    });
                                });
                                if (counts[dept][date].employees === undefined){ counts[dept][date].employees = 0; }
                                counts[dept][date].employees += 1; //
                            });
                        }
                        break;
                    default : break;
                        
                }
            }
        });
    });

    return counts;
}