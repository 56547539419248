import React, { useEffect, useState } from 'react';

const TopBar = ({sbToggle, userData})=>{
  const [userInits, SetUserInits] = useState('');
  useEffect(()=>{
    try{
      if (!Window.userData.name){ return; }
      let userName = Window.userData.name;
      let inits = userName.split(' ').map((i)=> i[0]).join("").slice(0,2);
      SetUserInits(inits);
    }catch(e){}
    
  },[userData]);

  return (
    <div className = "topbar flex flex-row">
      <div className = "menuContainer flex items-center justify-center w-[55px] hover1 rounded-lg" onClick = { sbToggle } >
        <svg className = "whiteSvg h-[24px] w-[24px]" viewBox="0 0 24 24" fill="none"><path d="M3 12H21M3 6H21M3 18H15" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
      </div>

      <div className = "userCircle flex items-center justify-center h-[40px] w-[40px] absolute right-[0.5em] top-[7px] noSelect">
        { userInits }
      </div>
    </div>
  )
}

export default TopBar;
