import React, { useEffect, useState }from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Home, Login, TopBar, SideBar, VerifyLogin, Shipping, Receiving, Detamper, Repair, Admin, Inventory, Dev, SetGlobals } from './imports.js';
//import { useNavigate } from "react-router-dom";
import './App.css';
const auth = getAuth();


export function App() {
  return (
    <BrowserRouter>
      <PageLayout>
        <Routes>
            <Route path = "/" element = { <Home /> } />
            <Route path = "/shipping" element = { <Shipping /> } />
            <Route path = "/receiving" element = { <Receiving /> } />
            <Route path = "/detamper" element = { <Detamper /> } />
            <Route path = "/repair" element = { <Repair /> } />
            <Route path = "/inventory" element = { <Inventory /> } />
            <Route path = "/admin" element = { <Admin /> } />
            <Route path = "/dev" element = { <Dev /> } />
        </Routes>
      </PageLayout>
    </BrowserRouter>
  );
}

const PageLayout = ({ children }) =>{

  const [loggedIn, SetLoggedIn ] = useState(null);
  const [SideBarActive, SetSidebar] = useState(null);
  const [userData, SetUserData] = useState(null);

  useEffect(()=>{
    onAuthStateChanged(auth, async (user)=>{
      console.log('Auth State Changed');
      let validLogin = await VerifyLogin(user);
      let loggedIn = (user && validLogin);
      SetLoggedIn(loggedIn);
      SetUserData(user);
      if (loggedIn){
        SetGlobals();
      }
    });
  }, []);

  const ToggleSidebar = ()=>{
    if (SideBarActive){
      SetSidebar(null);
    }else{
      SetSidebar(true);
    }
  }

  return (
    loggedIn ? (
      <div className = "absolute inset-0">
        <TopBar sbToggle = {ToggleSidebar} userData = {userData} />
        <SideBar sbToggle = {ToggleSidebar} sbActive = {SideBarActive} userData = { userData }/>
        <div className = "mainApp">
          {children}
        </div>
      </div>
    ) : (
      <Login />
    )
  )
  
}

export default App;
