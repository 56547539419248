import React, { useEffect, useState }from 'react';
import logo from "../prestoWhite.webp";
import {  signOut } from "firebase/auth";
import { NavLink } from "react-router-dom";


function SideBar({sbToggle, sbActive, userData}) {
  const [SbClass, SetSbClass] = useState('sideBar');
  const [isAdmin, SetIsAdmin ] = useState(null);
  const [isDev, SetIsDev ] = useState(null);

  useEffect(()=>{
    if (sbActive){ SetSbClass('sideBar sideBarActive');
    }else{ SetSbClass('sideBar'); }
  }, [sbActive]);

  useEffect(()=>{
    let user = Window.userData;
    if (user.isAdmin === true){
      SetIsAdmin(true);
    }else{ SetIsAdmin(null);}

    if (user.email === "cholt@elacarte.com" || user.email === "coltcholt@gmail.com"){
      SetIsDev(true);
      Window.isDev = true;
    }else{ SetIsDev(null); }

  },[userData]);

  let sbTimer;
  const sbOut = ()=>{
    sbTimer = setTimeout(()=>{
      if (sbActive){ sbToggle(); }
    }, 1_500);
  }

  const sbIn = ()=>{
    try{ clearTimeout(sbTimer);}catch(e){ }
  }

  const logOut = ()=>{
    signOut(Window.auth);
    sbToggle();
  }

  return (
    <div className = { SbClass } id = "sideBar" onMouseLeave = { sbOut } onMouseEnter = { sbIn }>
      <div className = "w-[100%]">
        <img src = { logo } className = "logo h-[30%] mx-auto my-6 opacity-90" alt="Presto"></img>
      </div>
      <div className = "w-[100%] mt-4">
        <nav>
          <NavLink to = "/" onClick = { sbToggle }>Home</NavLink>
          <NavLink to = "/shipping" onClick = { sbToggle }>Shipping</NavLink>
          <NavLink to = "/receiving" onClick = { sbToggle }>Receiving</NavLink>
          <NavLink to = "/detamper" onClick = { sbToggle }>Detamper</NavLink>
          <NavLink to = "/repair" onClick = { sbToggle }>Repair</NavLink>
          <NavLink to = "/inventory" onClick = { sbToggle }>Inventory</NavLink>
          { isAdmin ? <NavLink to = "/admin" onClick = { sbToggle }>Admin</NavLink> : "" }
          { isDev ? <NavLink to = "/dev" onClick = { sbToggle }>Dev</NavLink> : "" }
        </nav>
      </div>
      <div className = "logoutWrapper absolute bottom-0 w-[100%] h-[48px] greyFont flex items-center justify-center noSelect font-medium" onClick = { logOut }>
        Log Out
      </div>
    </div>
  )
}

export default SideBar;
