import React, {useEffect, useState } from 'react'

function DepartmentSelector({ update, opts, overrideOpts }) {
    var ddOptions = ['Shipping', 'Receiving', 'Repair', 'Detamper'];
    if (opts){
        opts.map((opt)=>{ ddOptions.push(opt); });
    }

    if (overrideOpts){
        ddOptions = [];
        overrideOpts.map((opt)=>{ ddOptions.push(opt); });
    }

    const [selectedDropDown, SetDropDown] = useState(null);
    const [selectedOption, SetOption] = useState(ddOptions[0]);

    const ddToggle = ()=>{
        SetDropDown(!selectedDropDown);
    }
    const toggleOption = (event) =>{
        let selected = event.target.innerHTML;
        SetOption(selected);
        update(selected);
    }


    return (
        <div className = "m-2 p-2 dropdown" style = {{ zIndex : '50'}} onClick = { ddToggle }>
            <div className = "noSelect">{selectedOption}</div>
            <div className = "dropDownOptions flex flexColumn" style = { selectedDropDown ? { height : ((ddOptions.length*36) - 26).toString()+'px' } : {  }}>
                { ddOptions.map((option)=>{
                    let classAdd = "";
                    if (selectedOption === option){
                        classAdd = "ddOptionHidden";
                    }
                    return (
                        <div key = {option} className = {`ddOption noSelect ${classAdd}`} onClick = {toggleOption} >
                            {option}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default DepartmentSelector;
