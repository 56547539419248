import React, {useEffect, useState, useId } from 'react';

function DateSelector({ update, direction }) {
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let fullMonths = ['January', 'February', 'March', 'April', 'May', 'June','July', 'August', 'September', 'October', 'November', 'December'];

    let years = []
    for(let i = 0; i < 10; i++){ years.push((2022+i).toString())}
    const [yearActive, SetYearActive] = useState("container yearContainer");
    let year = new Date().getFullYear().toString();
    let currentMonth = new Date().getMonth();
    currentMonth = months[currentMonth];

    const [selectedYear, SetYear] = useState(year);
    const [selectedMonth, SetMonth] = useState(fullMonths[months.indexOf(currentMonth)]);
    const [activeSelector, SetActiveSelector] = useState(null);
    const [selection, SetSelection] = useState(selectedMonth+' '+selectedYear);
    const [classDirection, setDirection] = useState('');
    useEffect(()=>{
        if (direction){
            setDirection('dateSelectorBoxRight');
        }
    },[direction]);


    const HandleYearChange = () =>{
        SetYearActive("container yearContainer active");
    }
    const HandleYearClick = (event)=>{
        let selected = event.target.innerHTML;
        SetYear(selected);
        SetYearActive("container yearContainer");
    }
    const HandleMonthClick = (event)=>{
        let selected = event.target.innerHTML;
        SetMonth(fullMonths[months.indexOf(selected)]);
        SetSelection(fullMonths[months.indexOf(selected)]+' '+selectedYear);
        let month = (months.indexOf(selected)+1);
        if (month < 10){ month = "0"+month.toString(); }
        month = month.toString();
        update(month+'-'+selectedYear.toString().slice(-2));
        ToggleSelector();
    }
    const ToggleSelector = ()=>{
        if (activeSelector){
            SetYearActive("container yearContainer");
        }
        SetActiveSelector((!activeSelector));
        SetMonth(selection.split(' ')[0]);
        SetYear(selection.split(' ')[1]);
    }

    let hoverTimer;
    const hoverOut = ()=>{
        hoverTimer = setTimeout(()=>{
            if (activeSelector){
                ToggleSelector();
            }
        },1_250);
    }
    const hoverIn = ()=>{
        try{
            clearTimeout(hoverTimer);
        }catch(e){ }
    }

    return (
        <div className = "absolute inset-0">
            <div className = "w-[100%] p-4 borderBottom dateSelector" onClick = { ToggleSelector }>
                <div className = "selectedMy">
                    <div className = "noSelect text-xl font-normal">{ selection.split(' ')[0] }</div>
                    <div className = "noSelect greyFont text-lg font-medium">{ selection.split(' ')[1] }</div>
                </div>
            </div>
            <div className = {activeSelector ? `dateSelectorBox activeDateSelector ${classDirection}` : `dateSelectorBox ${classDirection}`} onMouseLeave = { hoverOut } onMouseEnter = { hoverIn }>
                <div className = "container">
                    <div className = "container">
                        <div className = "grid">
                            { months.map((month)=>{
                                return (
                                    <div key = {month.toString()+'Selector'} onClick = {HandleMonthClick} className = {(month === currentMonth && selectedYear === year)? "monthSelector noSelect current" : "monthSelector noSelect"}>
                                        <div className = "noSelect">{month}</div>
                                    </div>
                                )
                            }) }
                        </div>
                        <div className = "noSelect shiftYearText " onClick = { HandleYearChange }>{selectedYear}</div>
                    </div>
                    <div className = { yearActive }>
                        <div className = "grid">
                            { years.map((yeartext)=>{
                                return (
                                    <div key = {yeartext.toString()+'Selector'} className = {(year === yeartext) ? "monthSelector noSelect current" : "monthSelector noSelect"}>
                                        <div className = "noSelect" onClick = { HandleYearClick }>{yeartext}</div>
                                    </div> 
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DateSelector;
