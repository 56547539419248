import React, { useEffect, useState, useRef } from 'react';
import { DateSelector, DepartmentSelector, GetCurrentDate, DepartmentOverview, ReadDB, AdminCalendar } from '../imports';
import { DepartmentAreaChart, CalCounters } from "../imports";
const employees = ["John Broderick","Devin Deason","Oladipupo Lawal","Ikenna Nnaji","Kehinde Oladipo","Joshua Olasehinde",
    "Olanike Olasehinde","Esther Oluwatayo","Amparo Potter","Oludolapo Temidayo",
    "Stefani Castellanos","Ray Sowels","Osiris Ellis","Amanda Bazarow","Omar Gomez"
].sort();

function Admin() {

  let today = GetCurrentDate();
  const [selectedDate, SetSelectedDate] = useState(today);
  const [dataset, SetDataset] = useState({});
  const [selectedDept, setDept] = useState('Shipping');

  const adminRef = useRef(null);
  return (
    <div className = "absolute inset-0 flex flex-row overflow-hidden adminPage" ref = {adminRef}>
      <div className = "adminRight w-[100%] yScroll relative flex flex-row">
        <div className = "flex flex-col adminDark">
          <div className = "relative area ">
            <DepartmentAreaChart date = { selectedDate } selectedDept = {selectedDept} />
          </div>
          

          <div className = "adminShifts relative">
            <div className = "altCard absolute inset-0 top-0 right-0 overflow-hidden">
              <AdminCalendar setData = {SetDataset} date = { selectedDate }/>
            </div>
          </div>
        </div>
        
        <div className = "adminPanel w-[20%] min-w-[200px]">
          <div className = "w-[100%] h-[90px] min-h-[90px] relative">
            <DateSelector update = { SetSelectedDate } direction = {"right"}/>
          </div>
          
          <div className = "relative deptOvw">
            <DepartmentOverview date = { selectedDate }/>
          </div>
          <div className = "adminBottomRight relative">
            <CalCounters dataset = {dataset} date = { selectedDate } setDept = {setDept}  selectedDept = {selectedDept}/>
          </div>
        </div>
        
      </div>

      
      
    </div>
  )
}

export default Admin;


