import React from 'react'

function Shipping() {
  return (
    <div>
      
    </div>
  )
}

export default Shipping;
