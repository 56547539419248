import React, { useEffect, useState, useRef } from 'react';
import { ReadDB, DepartmentSelector, DownloadCSV, GetCurrentDate } from "../imports";

function InventoryOverview({ counts }) {
    const [data, setData] = useState(null);
    const [selectedSet, setSelected] = useState('Table Top');
    const [overrides, setOverrides] = useState(['Table Top']);
    const [parts, setParts] = useState([]);
    const partArr = [
        'PSTA3-000-R',
        'PSTA3-000',
        'CHRA2-000-R',
        'CHRA2-000',
        'BAT2-000-R',
        'BAT2-000',
    ]

    useEffect(()=>{
        setData(counts);
        let parts = Window.parts;
        if (!parts){ return; }
        let sets = [];

        Object.keys(parts).map((part)=>{
            if (Window.parts[part].set !== undefined) {
                sets.push(Window.parts[part].set);
            }
        });

        let partOpts = [];
        Object.keys(parts).forEach((p)=>{ if (partOpts.indexOf(p) === -1){ partOpts.push(p); } });
        if (counts){
            Object.keys(counts).forEach((p)=>{ if (partOpts.indexOf(p) === -1){ partOpts.push(p); }});
        }

        partOpts = partOpts.sort();
        if (selectedSet === "Table Top"){
            let newArr = [];
            partArr.forEach((p)=>{ newArr.push(p); });
            partOpts.forEach((p)=>{ if (newArr.indexOf(p) === -1){ newArr.push(p); }});
            partOpts = newArr;
        }

        setParts(partOpts);
        sets = Array.from(new Set(sets));
        setOverrides(sets);
    },[counts]);


    // 2/28  Received Transfer In   Transfer Out Shipped Scrapped  Total   Scanned;
    return (
    <div className = "absolute inset-0 flex flex-col">
      <div className = "invTable flex flex-col">
        <div className = "absolute inset-0 iTable">
            <div className = "grid grid-cols-9 grid-rows-1 w-[100%] h-[60px] min-h-[60px] " style = {{gridTemplateColumns : '3fr repeat(10, 2fr)'}}>
                <DepartmentSelector overrideOpts = {overrides} update = {setSelected} />
                
                <div className = "flex h-[100%] itCell noSelect items-center font-medium greyFont bRight">Last Scan</div>
                <div className = "flex h-[100%] itCell noSelect items-center font-medium greyFont">Received</div>
                <div className = "flex h-[100%] itCell noSelect items-center font-medium greyFont bRight">Transfers In</div>
                <div className = "flex h-[100%] itCell noSelect items-center font-medium greyFont">Transfers Out</div>
                <div className = "flex h-[100%] itCell noSelect items-center font-medium greyFont">Shipped</div>
                <div className = "flex h-[100%] itCell noSelect items-center font-medium greyFont bRight">Scrapped</div>
                <div className = "flex h-[100%] itCell noSelect items-center font-medium greyFont bRight">Issue Adj</div>
                <div className = "flex h-[100%] itCell noSelect items-center font-medium greyFont">Total</div>
                <div className = "flex h-[100%] itCell noSelect items-center font-medium greyFont">Scanned</div>
                <div className = "flex h-[100%] itCell noSelect items-center font-medium greyFont">Variance</div>
            </div>
            <div className = "invDetails flex flex-col yScroll scrollBar">
                { parts ? 
                    parts.map((part)=>{
                        let classAdd = "";
                        if ((Window.parts?.[part]?.set === selectedSet) || (Window.parts?.[part.toString()+'-R']?.set === selectedSet)){
                            classAdd = "invDetailRow";
                        }

                        let issueAdj = 0;
                        issueAdj += (counts?.[part]?.issues?.overShipped || 0);
                        issueAdj = issueAdj - (counts?.[part]?.issues?.overReceived || 0);
                        issueAdj += (counts?.[part]?.issues?.unexpectedScan || 0);
                        issueAdj += (counts?.[part]?.issues?.overScrapped || 0);
                        issueAdj = issueAdj - (counts?.[part]?.issues?.underTransferred || 0);
                        issueAdj += (counts?.[part]?.issues?.overTransferred || 0);

                        let lmScan = (counts?.[part]?.lmCount || 0);
                        let received = (counts?.[part]?.receiving || 0);
                        let tIn = (counts?.[part]?.transfersIn || 0);
                        let tOut = (counts?.[part]?.transfersOut || 0);
                        let shipped = (counts?.[part]?.shipping || 0); // overShipped | overReceived | unexpectedScan |  overScrapped | underTransferred tIn already had | overTransferred/tout did not have | 
                        let scrapped = (counts?.[part]?.Scrap || 0);
                        let total = (counts?.[part]?.eCount || 0);
                        total += issueAdj;
                        let scanned = (counts?.[part]?.count || 0);

                        


                        let variance = scanned - total;
                        let varClass = "greenFont";
                        let varNum = variance;
                        if (varNum < 0){ varNum = varNum * -1}
                        if (varNum > 10){ varClass = "yellowFont" }
                        if (varNum > 20){ varClass = "redFont"}


                        return (
                        <div className = {`hideRow ${classAdd} grid grid-cols-9 grid-rows-1 w[100%]`} key = {`${part}InvDetailRow`} style = {{gridTemplateColumns : '3fr repeat(10, 2fr)'}}>
                            <div className = "flex h-[100%] itCell noSelect items-center">{part.toLocaleString('en-us') }</div>
                            <div className = "flex h-[100%] itCell noSelect items-center bRight">{lmScan.toLocaleString('en-us') }</div>
                            <div className = "flex h-[100%] itCell noSelect items-center">{received.toLocaleString('en-us') }</div>
                            <div className = "flex h-[100%] itCell noSelect items-center bRight">{tIn.toLocaleString('en-us') }</div>
                            <div className = "flex h-[100%] itCell noSelect items-center">{tOut.toLocaleString('en-us') }</div>
                            <div className = "flex h-[100%] itCell noSelect items-center">{shipped.toLocaleString('en-us') }</div>
                            <div className = "flex h-[100%] itCell noSelect items-center bRight">{scrapped.toLocaleString('en-us') }</div>
                            <div className = "flex h-[100%] itCell noSelect items-center bRight">{issueAdj.toLocaleString('en-us') }</div>
                            <div className = "flex h-[100%] itCell noSelect items-center">{total.toLocaleString('en-us') }</div>
                            <div className = "flex h-[100%] itCell noSelect items-center">{scanned.toLocaleString('en-us') }</div>
                            <div className = {`flex h-[100%] itCell noSelect items-center ${varClass}`}>{variance.toLocaleString('en-us') }</div>
                        </div>
                        )
                    })
                    :
                    <div></div>
                }
            </div>
        </div>
      </div>
      <div className = "itBottom w-[100%] h-[60px] flex flex-row gap-3">
        <div className = "flex h-[100%] items-center font-medium noSelect mx-3">Download :</div>
        <div className = "dlOption noSelect greyFont" onClick = {()=>{ DownloadVariance(); }}>Variance</div>
        <div className = "dlOption noSelect greyFont" onClick = {()=>{ DownloadScans(); }}>Scans</div>
      </div>
    </div>
  )
}

function DownloadScans(){
    ReadDB('Inventory/scans', (data)=>{
        let arr = [['Part Number','Serial Number','Location','Qty']];
        Object.keys(data).map((sn)=>{
            arr.push([data[sn].part, sn, data[sn].location, data[sn].qty]);
        });
        DownloadCSV({ data : arr, title : 'Scans' });
    });
}

function DownloadVariance(){
    ReadDB('Inventory/current',(expectedInv)=>{
        ReadDB('Inventory/scans', (data)=>{
            let arr = [['Part Number','Serial Number','Issue','Qty']];
            Object.keys(data).map((sn)=>{
                if (expectedInv[sn] === undefined){
                    arr.push([data[sn].part, sn, 'Unexpected Scan', data[sn].qty]);
                }else{
                    let qty = data[sn].qty;
                    if (qty > expectedInv[sn].qty){
                        arr.push([data[sn].part, sn, 'Over', (qty - expectedInv[sn].qty)]);
                    }
                    expectedInv[sn].qty = expectedInv[sn].qty - qty;
                    if (expectedInv[sn].qty < 1){
                        delete expectedInv[sn];
                    }
                }
            });
            try{
                Object.keys(expectedInv).map((sn)=>{
                    arr.push([expectedInv[sn].part, sn, 'Short', expectedInv[sn].qty ]);
                });
            }catch(e){

            }

            ReadDB('Inventory/issues', (data)=>{
                try{
                    Object.keys(data).map((dept)=>{
                        if (dept === "scans"){ return; }
                        Object.keys(data[dept]).map((sn)=>{
                            let issue = "NA";
                            let part = "NA";
                            if (data[dept][sn].part !== undefined){ part = data[dept][sn].part }
                            if (data[dept][sn].issue !== undefined){ issue = data[dept][sn].issue }
                            if (data[dept][sn].partIssue !== undefined){ issue = data[dept][sn].partIssue }

                            arr.push([part, sn, issue, "1"]);
                        });
                    });
                }catch(e){ }
                

                DownloadCSV({ data : arr, title : 'Variance' });
            })
            
        });
    })
}

export default InventoryOverview;
