import React, { useEffect, useState, useRef } from 'react';
import { DepartmentSelector, GetCurrentDate, GetLastDay, ReadDB, WriteDB, WatchDB, IdPart, CheckSerialized, InventoryOverview, GetLongDate } from "../imports";
import { ref, get, set, child, onValue} from "firebase/database";

function Inventory() {
  
  const ddOptions = ['Sync','Set Movement','Load Previous','Clear','Finalize'];
  const [isDev, setIsDev] = useState(null);
  const [ddSelection, setSelection] = useState(ddOptions[0]);
  const [mainCounts, setMainCounts] = useState(null); // [ part, eCount, count ]
  const [selectedShelf, setSelectedShelf] = useState("DF-1-A");
  const [shelfListener, setShelfListener] = useState(null);
  const [currentShelf, setCurrentShelf] = useState(null);
  const [currentPns, setCurrentPns] = useState(null);
  const [hoverClass, setHoverClass] = useState("");
  const [countsData, setCountsData] = useState(null);
  const [lastInvDate, setLastInvDate] = useState('');

  const partArr = [
    ['All',0,0],
    'PSTA3-000-R',
    'PSTA3-000',
    'CHRA2-000-R',
    'CHRA2-000',
    'BAT2-000-R',
    'BAT2-000',
  ]

  useEffect(()=>{
    let [area, rack, shelf] = selectedShelf.toString().split('-');
    a1.current.innerHTML = area.toString(); a2.current.innerHTML = area.toString();
    r1.current.innerHTML = rack.toString(); r2.current.innerHTML = rack.toString();
    s1.current.innerHTML = shelf.toString(); s2.current.innerHTML = shelf.toString();

    try{ Window.shelfListener(); }catch(e){ }

    Window.shelfListener = onValue(ref(Window.database, `Inventory/locations/scans/${area}/${rack}/${shelf}`), (data)=>{
      if (!data.exists()){ data = undefined; }else{ data = data.val(); }
      setCurrentShelf(data);
    });
    
  },[selectedShelf]);

  useEffect(()=>{
    if (!currentShelf){ return; }
    try{
      let counts = {};
      Object.keys(currentShelf).map((sn)=>{
        let part = currentShelf[sn].part;
        if (counts[part] === undefined){ counts[part] = 0; }
        let count = currentShelf[sn].qty;
        counts[part] += count;
      });
      setCurrentPns(counts);
    }catch(e){ console.log(e); }
    
  },[currentShelf])

  useEffect(()=>{
    setIsDev(Window.isDev);
    invInput.current.focus();
  },[Window.isDev]);

  const runDev = ()=>{
    switch (ddSelection){
      case 'Set Movement' :
        SetMovement();
        break;
      case 'Load Previous' :
        ReadDB('Main/02-23/02-28-23/inventory/PLC', (data)=>{
          let mainCounts = {};
          let scanLocs = data;
          let scanCounts = {};
          let scans = {};

          Object.keys(data).map((area)=>{
            if (scanCounts[area] === undefined){ scanCounts[area] = {}; }
            Object.keys(data[area]).map((rack)=>{
              if (scanCounts[area][rack] === undefined){ scanCounts[area][rack] = {}; }
              Object.keys(data[area][rack]).map((shelf)=>{
                if (scanCounts[area][rack][shelf] === undefined){ scanCounts[area][rack][shelf] = {}; }
                Object.keys(data[area][rack][shelf]).map((sn)=>{
                  let vals = data[area][rack][shelf][sn];
                  let qty = vals.qty;
                  let part = vals.part;
                  if (mainCounts[part] === undefined){ mainCounts[part] = 0};
                  mainCounts[part] += qty;
                  if (scanCounts[area][rack][shelf][part] === undefined){ scanCounts[area][rack][shelf][part] = 0; }
                  scanCounts[area][rack][shelf][part] += qty;
                  if (scans[sn] === undefined){ scans[sn] = {
                    location : `${area}-${rack}-${shelf}`,
                    part : part,
                    qty : qty,
                  }}
                });
              });
            });
          });

          console.log(mainCounts);
          console.log(scanCounts);

          if (!Window.testMode){ 
            
            WriteDB(`Inventory/scans`, scans);
            WriteDB(`Inventory/locations/scans`, scanLocs); 
            WriteDB(`Inventory/locations/counts`, scanCounts); 

            Object.keys(mainCounts).map((part)=>{
              let count = mainCounts[part];
              WriteDB(`Inventory/Counts/${part}/count`, count);
            });
          }
          

        });
        break;
      case 'Clear' :
        ReadDB('Inventory', (data)=>{
          WriteDB('InventoryBackup', data);
          setTimeout(()=>{ WriteDB('Inventory', null);}, 1500);
        })
    }

  }
  
  const HandleScan = (event)=>{
    let input = event.target.value;

    if (input.toString().slice(0,2) === "$$"){
      if (input.toString().split('-').length === 3){
        try{ Window.shelfListener(); }catch(e){ console.log(e); }
        input = input.toString().firebaseFormat();
        input = input.split('-');
        let inputVal = [];
        input.map((key)=>{
          if (!isNaN(parseInt(key))){ key = parseInt(key).toString(); }
          inputVal.push(key);
        });
        inputVal = inputVal.join('-');
        console.log(inputVal);
        setSelectedShelf(inputVal);
      
        return;
      }
    }

    input = input.toString().firebaseFormat();

    let data = Window.expectedScans?.[input];
    let part = IdPart(input);
    if (!data){
      WriteDB(`Inventory/issues/scans/${part}/${input}`, {
        unexpectedScan : true,
      });
      if (countsData){
        let usCount = (countsData?.[part]?.issues?.unexpectedScan || 0);
        console.log(usCount);
        WriteDB(`Inventory/Counts/${part}/issues/unexpectedScan`, usCount + 1);
      }
    }else{
      if (data.part !== undefined){ part = data.part; }
    }
    let mainCount = (Window.inventoryCounts?.[part]?.count || 0);
    let noCount = false; 

   

    ReadDB(`Inventory/scans/${input}`, (data)=>{
      if (data?.location !== undefined){
        let currentLoc = data?.location.toString().split('-');
        var currentCount = (Window.invCounts?.[currentLoc[0]]?.[currentLoc[1]]?.[currentLoc[2]]?.[part]) || 0;
        console.log(currentLoc);
        WriteDB(`Inventory/locations/counts/${currentLoc[0]}/${currentLoc[1]}/${currentLoc[2]}/${part}`, currentCount - 1);
        WriteDB(`Inventory/locations/scans/${currentLoc[0]}/${currentLoc[1]}/${currentLoc[2]}/${input}`, null);
        noCount = true;
      }
      
      if(!noCount){ mainCount += 1;}

      let selectedLoc = selectedShelf.split('-');
      var currentCount = (Window.invCounts?.[selectedLoc[0]]?.[selectedLoc[1]]?.[selectedLoc[2]]?.[part]) || 0;
      WriteDB(`Inventory/locations/counts/${selectedLoc[0]}/${selectedLoc[1]}/${selectedLoc[2]}/${part}`, currentCount + 1);
      WriteDB(`Inventory/locations/scans/${selectedLoc[0]}/${selectedLoc[1]}/${selectedLoc[2]}/${input}`, { part : part, qty : 1 });
      WriteDB(`Inventory/Counts/${part}/count`, mainCount);
      WriteDB(`Inventory/scans/${input}`, {
        location : selectedShelf,
        part : part,
        qty : 1,
      });


    });


  }

  const onEnter = (event)=>{
    if (event.key === "Enter"){
      HandleScan(event);
      return true;
    }
    return false;
    
  }

  useEffect(()=>{
    WatchDB('Inventory/Counts', (data)=>{
      if (!data){ return; }
      setCountsData(data);
      Window.inventoryCounts = data;
      let vals = [...partArr];
      vals[0][1] = 0;
      vals[0][2] = 0;
      Object.keys(data).map((part)=>{
        if (data[part].eCount === undefined){ return; }
        if (vals.indexOf(part) === -1){ vals.push(part); }
        let eCount =(data?.[part]?.eCount || 0);
        let count = (data?.[part]?.count || 0); 
        if (typeof(vals[vals.indexOf(part)]) === "string"){
          vals[vals.indexOf(part)] = [ part, eCount, count ];
        }

        if (Window.parts[part]?.serialized === true){
          vals[0][2] += count;
          vals[0][1] += eCount;
        }
        
      });


      console.log(vals);
      setMainCounts(vals);
    });

    WatchDB('Inventory/current', (data)=>{
      Window.expectedScans = data;
    });

    WatchDB('Inventory/locations/counts', (data)=>{
      Window.invCounts = data;
    });

    WatchDB('Inventory/lastDate', (data)=>{
      if (!data){ return; }
      setLastInvDate(data.toString());
    })

  },[]); 

  const [a1, a2, r1, r2, s1, s2] = [useRef(),useRef(),useRef(),useRef(),useRef(),useRef()];

  const popupRef = useRef(null);
  const invInput = useRef(null);

  const [popupClass, setPopupClass] = useState('');

  return (
    <div className = "absolute inset-0 flex flex-row darkBg" onClick = {()=>{ invInput.current.focus(); }}>
      
      <div className = "invLeft flex flex-col h-[100%] w-[100%] relative overflow-hidden">




        <div className = {`invPopup absolute darkBg ${popupClass} flex flex-col`} ref = {popupRef}>



          <div className = "flex flex-col invContainer relative">
            <InventoryOverview counts = {countsData} />
          </div>
          



        </div>








        <div className = "header flex flex-row w-[100%] flex-wrap px-0">
          <div className = "flex flex-row gap-1 items-center mr-3 ml-3">
            <div className = "noSelect font-medium text-lg" ref = {a1}>DF</div>
            <div className = "loc noSelect font-medium ">-</div>
            <div className = "noSelect font-medium  text-lg" ref = {r1}>01</div>
            <div className = "loc noSelect font-medium">-</div>
            <div className = "noSelect font-medium  text-lg" ref = {s1}>A</div>
          </div>
          {
            currentPns ? 
            Object.keys(currentPns).map((part, i)=>{
              let count = currentPns[part];
              return (
                <div className = "pCounter flex flex-row" key = {`${part}${i}`} onMouseOver = {(e)=>{ setHoverClass(part); }} onMouseLeave = {(e)=>{ setHoverClass(null); }}>
                  <div className = "pLeft noSelect">{part}</div>
                  <div className = "pRight noSelect">{count}</div>
                </div>
              )
            })
              :
            <div></div>
          }
        </div>
        <div className = {`invScanBody flex flex-row flex-wrap mx-0 yScroll scrollBar ${hoverClass}`}>
          {
            currentShelf ? 
              Object.keys(currentShelf).map((sn, i)=>{
                let classAdd = "";
                if (currentShelf[sn].part === "NA"){ classAdd = "naPart"; }
                return <div className = {`scan ${classAdd} noSelect ${currentShelf[sn].part}Hover`} key = {`${sn}${i}`}>{sn}</div>
              })

            :

            <div></div>
          }
        </div>


      </div>
      <div className = "invRight flex flex-col h-[100%] w-[18%] min-w-[200px]">
        <div className = "flex flex-col w-[100%]">
          <div className = "location grid grid-cols-3 grid-rows-1 items-center justify-items-center m-[1em] mb-[0em] w-[60%] mx-auto">
            <div className = "loc noSelect greyFont font-medium" ref = {a2}>DF</div>
            <div className = "loc noSelect greyFont font-medium" ref = {r2}>01</div>
            <div className = "loc noSelect greyFont font-medium" ref = {s2}>A</div>
          </div>
          <input ref = {invInput} className = "invInput" type = "text" autoComplete='off' autoCorrect='off'
            onBlur = {(e)=>{ invInput.current.focus() } } 
            onInput = {(e)=>{ e.target.value = e.target.value; }} 
            onKeyDown = {(e)=>{ if(onEnter(e)){ e.target.value = ""; }; }}>
          </input>


        </div>
        <div className = "invOptions flex flex-row h-[48px] m-2 mt-0 gap-1">
            <div className = "invOption flex items-center justify-center" onClick = {(e)=>{ 
                if (popupClass === "activePopup"){
                  setPopupClass(''); 
                  return;
                }
                setPopupClass('activePopup'); 
              
              }}>
              <svg className = "whiteSvg" fill="#000000" width="28px" height="24px" viewBox="0 0 32 32">
                <path d="M27 21.031h-20v5h20c0.552 0 1-0.447 1-1v-3c0-0.552-0.448-1-1-1zM22 19.031v-3c0-0.552-0.448-1-1-1h-14v5h14c0.552 0 1-0.447 1-1zM16 13.031v-3c0-0.552-0.448-1-1-1h-8v5h8c0.552 0 1-0.448 1-1zM10 7.031v-3c0-0.553-0.448-1-1-1h-2v5h2c0.552 0 1-0.448 1-1zM5 3.031h-1v25.938h24v-1h-23v-24.938z"></path>
              </svg>
            </div>
            <div className = "invOption flex items-center justify-center" onClick = {()=>{ setPopupClass('');  }}>
              <svg className = "whiteSvg" fill="#000000" width="24px" height="24px" viewBox="0 0 24 24"><path d="M8,2A1,1,0,0,1,8,4H4V8A1,1,0,0,1,2,8V3A1,1,0,0,1,3,2ZM8,20H4V16a1,1,0,0,0-2,0v5a1,1,0,0,0,1,1H8a1,1,0,0,0,0-2Zm13-5a1,1,0,0,0-1,1v4H16a1,1,0,0,0,0,2h5a1,1,0,0,0,1-1V16A1,1,0,0,0,21,15Zm0-6a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H16a1,1,0,0,0,0,2h4V8A1,1,0,0,0,21,9Zm1,2H2a1,1,0,0,0,0,2H22a1,1,0,0,0,0-2Z"/></svg>
            </div>
        </div>  
        { isDev ? 
            <div className = "w-[100%] flex flex-col devOptions">
              <DepartmentSelector overrideOpts = {ddOptions} update = {setSelection} />
              <button className = "devButton" onClick = {runDev}>Execute</button>
            </div>
          :
            <div></div> 
        }

        <div className = "invBottom flex flex-col h-[100%] relative w-[100%] overflow-hidden">
          <div className = "completionCounters scrollBar flex flex-col w-[100%] h-[100%] relative yScroll">
            {
              mainCounts ? 
                  mainCounts.map((row, i)=>{
                    if (Window.parts[row[0]]?.set === "Non Inventory"){ return; }
                    let target = row[1];
                    let actual = row[2];
                    let classVal = "";
                    if (Window.parts[row[0]]?.serialized === false){ classVal = "greyFont"; }
                    let percentage = (actual / target) * 100;
                    percentage = Math.round(percentage);
                    if (percentage > 99){ percentage = 100; }
                    return (
                      <div className = {`noSelect px-1 py-2 my-1 items-center relative h-[45px] min-h-[45px] partCounter ${classVal}`} key = {`${row[0]}Counter${i}`}>
                        <div className = "absolute inset-1 mx-1 flex overflow-hidden">
                          <div className = "absolute inset-0 targetBar p-1 flex items-center h-[34px] min-h-[34px] max-w-[100%]">
                            <div className = "noSelect ml-1">{row[0]}</div>
                          </div>
                          <div className = {`absolute left-0 progressBar flex items-center h-[34px] min-h-[34px]`} style = {{width : `${percentage}%`}}></div>
                        </div>
                        <div className = "numbrContainer relative flex flex-row gap-1 justify-center items-center">
                          <div className = "numberz noSelect ">{actual.toLocaleString('en-us')}</div>
                          <div className = "numberz noSelect"> / </div>
                          <div className = "numberz noSelect">{target.toLocaleString('en-us')}</div>
                        </div>
                        
                        
                      </div>
                    )
                  })
                :

                <div></div>
            }
          </div>
        </div>
        
      
      </div>
      
    </div>
  )
}

export default Inventory;

Window.mainData = {};

async function SetMovement(){
  let today = GetCurrentDate().toString().replaceAll('/','-');
  let month = today.slice(0,2)+'-'+today.slice(-2);
  let movement = {};
  let pullDate = today;
  let pullMonth = month;
  let dates = [];
  ReadDB('Inventory/scans', async (cScans)=>{
    for(let i = 0; i < 40; i++){
      let found = false;
      dates.push(pullDate);
      if (Window.mainData[pullMonth] === undefined){ Window.mainData[pullMonth] = {}}
      if (Window.mainData[pullMonth][pullDate] !== undefined){
        movement[pullDate] = Window.mainData[pullMonth][pullDate];
        if (movement[pullDate].inventory !== undefined){ found = true; }
      }else{
        await ReadDB(`Main/${pullMonth}/${pullDate}`, (data)=>{
          if (!data){ return; }
          movement[pullDate] = data;
          Window.mainData[pullMonth][pullDate] = data;
          if (data.inventory !== undefined){
            found = true;
          }
        });
      }
      
      if (found){ break; }
      pullDate = GetLastDay(pullDate).toString().replaceAll('/','-');
      pullMonth = pullDate.slice(0,2)+'-'+pullDate.slice(-2);
    }
  
    let expectedInv = {};
    let issues = {};
    let movementCounts = {};
    let lmCounts = {};
    let issueCounts = {};
  
    dates = dates.reverse();
    dates.map((date, i)=>{
      if (!movement[date]){ return; }
      let depts = Object.keys(movement[date]);
      if (i === 0){
        let ignore = ['Scrap','transfers'];
        ignore.forEach((val)=>{
          if (depts.indexOf(val) !== -1){
            depts.splice(depts.indexOf(val), 1);
          }
        });
  
        let invData = movement[date].inventory.PLC
        Object.keys(invData).map((area)=>{
          Object.keys(invData[area]).map((rack)=>{
            Object.keys(invData[area][rack]).map((shelf)=>{
              Object.keys(invData[area][rack][shelf]).map((sn)=>{
                expectedInv[sn.toString().toUpperCase()] = invData[area][rack][shelf][sn];
              });
            });
          });
        });
        WriteDB('Inventory/lastDate', date);
        lmCounts = GetInvCounts(expectedInv);
      }
  
      let data = movement[date];
  
      if (depts.indexOf('receiving') !== -1){
        var recData = data.receiving;
        Object.keys(recData).map((franchise)=>{
          Object.keys(recData[franchise]).map((customer)=>{
            Object.keys(recData[franchise][customer]).map((ticket)=>{
              Object.keys(recData[franchise][customer][ticket].data).map((tracking)=>{
                Object.keys(recData[franchise][customer][ticket].data[tracking]).map((sn)=>{
                  if (sn === "employee" || sn === "comments"){ return; }
                  var tData = recData[franchise][customer][ticket].data[tracking][sn];
                  let part = tData.part;
                  let idPart = IdPart(sn, part);

                  if (idPart !== "NA" && Window.parts?.[idPart]?.serialized === true){
                    if ((idPart !== part) && (idPart.toString().replace('-R','') !== part)){
                      if (issues.receiving === undefined){ issues.receiving = {}; }
                      if (issues.receiving[sn] === undefined){ issues.receiving[sn] = {}; }
                      issues.receiving[sn] = {
                        part : part,
                        issue : 'Part',
                      }

                      part = idPart;
                    }
                  }
                  

                  
                  try{
                    if (Window.parts[tData.part]?.serialized === false){
                      sn = tData.part;
                    }else if (Window.parts[tData.part]?.serialized === true){
                      if (expectedInv[sn] !== undefined){
                        expectedInv[sn].overReceived = true;
                        if (issues.receiving === undefined){ issues.receiving = {}; }
                        issues.receiving[sn] = {
                          part : tData.part,
                          issue : 'overReceived',
                        }
                        if (issueCounts[part] === undefined){ issueCounts[part] = {}; }
                        if (issueCounts[part].overReceived === undefined){ issueCounts[part].overReceived = 0};
                        issueCounts[part].overReceived += (tData.qty || 1);
                      }
                    }
                  }catch(e){ }
  
                  if (expectedInv[sn] === undefined){
                    expectedInv[sn] = {
                      part : tData.part,
                      qty : 0,
                    }
                  }
  
                  if (expectedInv[sn].qty === undefined){ expectedInv[sn].qty = 0; }
                  expectedInv[sn].qty += tData.qty;
                  if (movementCounts.receiving === undefined){ movementCounts.receiving = {}; }
                  if (movementCounts.receiving[part] === undefined){ movementCounts.receiving[part] = {};};
                  if (movementCounts.receiving[part].qty === undefined){ movementCounts.receiving[part].qty = 0;};
                  movementCounts.receiving[part].qty += tData.qty;
                });
              });
            });
          });
        });
  
  
      }
  
      if (depts.indexOf('transfers') !== -1){
        var tData = data.transfers;
        Object.keys(tData).map((fromLoc)=>{
          Object.keys(tData[fromLoc]).map((toLoc)=>{
            Object.keys(tData[fromLoc][toLoc]).map((sn)=>{
              var pData = tData[fromLoc][toLoc][sn];
              let part = pData.part;
              let qty = pData.qty;
              let isSerialized = CheckSerialized(sn, part);
  
              if (isSerialized){
                let idPart =IdPart(sn);
                let invPart = expectedInv[sn]?.part;
                if (part !== invPart && invPart !== undefined){
                  if (issues.transfers === undefined){ issues.transfers = {};}
                  if (issues.transfers[sn] === undefined){ issues.transfers[sn] = {}; }
                  issues.transfers[sn].partIssue = `Part Id'd as ${invPart ? invPart : idPart }`
                }
              }
  
  
              if (toLoc === "PLC"){
                if (expectedInv[sn] !== undefined && isSerialized){
                  expectedInv[sn].underTransferred = true;
                  if (issues.transfers === undefined){ issues.transfers = {}; }
                  issues.transfers[sn] = {
                    part : part,
                    issue : 'underTransferred',
                  }
                  if (issueCounts[part] === undefined){ issueCounts[part] = {}; }
                  if (issueCounts[part].underTransferred === undefined){ issueCounts[part].underTransferred = 0};
                  issueCounts[part].underTransferred += (qty || 1);
                }
                if (expectedInv[sn] === undefined){ expectedInv[sn] = {
                  part : part,
                  qty : 0,
                }}
                if (expectedInv[sn].qty === undefined){ expectedInv[sn].qty = 0; }
                expectedInv[sn].qty += qty;
  
                if (movementCounts.transfersIn === undefined){ movementCounts.transfersIn = {}; }
                if (movementCounts.transfersIn[part] === undefined){ movementCounts.transfersIn[part] = {};};
                if (movementCounts.transfersIn[part].qty === undefined){ movementCounts.transfersIn[part].qty = 0;};
                movementCounts.transfersIn[part].qty += qty;
  
  
              }else{
                if (expectedInv[sn] === undefined){
                  expectedInv[sn] = {
                    overTransferred : true,
                    part : part,
                    qty : 0,
                  }
                  if (issues.transfers === undefined){ issues.transfers = {}; }
                  issues.transfers[sn] = {
                    part : part,
                    issue : 'overTransferred',
                  }

                  if (issueCounts[part] === undefined){ issueCounts[part] = {}; }
                  if (issueCounts[part].overTransferred === undefined){ issueCounts[part].overTransferred = 0};
                  issueCounts[part].overTransferred += (qty || 1);
                }
                if (expectedInv[sn].qty === undefined){ expectedInv[sn].qty = 0; }
                expectedInv[sn].qty = expectedInv[sn].qty - qty;
                if (expectedInv[sn].qty < 1){ delete expectedInv[sn] }
  
                if (movementCounts.transfersOut === undefined){ movementCounts.transfersOut = {}; }
                if (movementCounts.transfersOut[part] === undefined){ movementCounts.transfersOut[part] = {};};
                if (movementCounts.transfersOut[part].qty === undefined){ movementCounts.transfersOut[part].qty = 0;};
                movementCounts.transfersOut[part].qty += qty;
              }
            });
          });
        });
      }
  
      if (depts.indexOf('shipping') !== -1){
        var shipData = data.shipping;
        Object.keys(shipData).map((franchise)=>{
          Object.keys(shipData[franchise]).map((customer)=>{
            Object.keys(shipData[franchise][customer]).map((ticket)=>{
              Object.keys(shipData[franchise][customer][ticket].data).map((tracking)=>{
                Object.keys(shipData[franchise][customer][ticket].data[tracking]).map((sn)=>{
                  if (sn === "employee" || sn === "comments"){ return; }
                  var tData = shipData[franchise][customer][ticket].data[tracking][sn];
                  let part = tData.part;
                  let isSerialized = CheckSerialized(sn, part);
  
                  if (isSerialized){
                    let idPart =IdPart(sn);
                    let invPart = expectedInv[sn]?.part;
                    if (part !== invPart && invPart !== undefined){
                      if (issues.shipping === undefined){ issues.shipping = {};}
                      if (issues.shipping[sn] === undefined){ issues.shipping[sn] = {}; }
                      issues.shipping[sn].partIssue = `Part Id'd as ${invPart ? invPart : idPart }`;
                      if (part !== idPart.toString().replace('-R','')){
                        part = idPart.toString();
                      }
                    }
                  }
                  
                  try{
                    if (Window.parts[tData.part]?.serialized === false){
                      sn = tData.part;
                    }else if (Window.parts[tData.part]?.serialized === true){
                      if (expectedInv[sn] === undefined){
                        expectedInv[sn].overShipped = true;
                      }
                    }
                  }catch(e){ }
  
                  if (expectedInv[sn] === undefined){
                    expectedInv[sn] = {
                      part : tData.part,
                      qty : 0,
                      overShipped : true,
                    }
                    if (issues.shipping === undefined){ issues.shipping = {}; }
                    if (issues.shipping[sn] === undefined){ issues.shipping[sn] = {};};
                    issues.shipping[sn].issue = 'overShipped';
                    issues.shipping[sn].part = part;

                    if (issueCounts[tData.part] === undefined){ issueCounts[tData.part] = {}; }
                    if (issueCounts[tData.part].overShipped === undefined){ issueCounts[tData.part].overShipped = 0};
                    issueCounts[tData.part].overShipped += (tData.qty || 1);
                  }
  
                  if (expectedInv[sn].qty === undefined){ expectedInv[sn].qty = 0; }
                  expectedInv[sn].qty = expectedInv[sn].qty - tData.qty;
                  if (expectedInv[sn].qty < 1){ delete expectedInv[sn] }
  
                  if (movementCounts.shipping === undefined){ movementCounts.shipping = {}; }
                  if (movementCounts.shipping[part] === undefined){ movementCounts.shipping[part] = {};};
                  if (movementCounts.shipping[part].qty === undefined){ movementCounts.shipping[part].qty = 0;};
                  movementCounts.shipping[part].qty += tData.qty;
                });
              });
            });
          });
        });
  
      }
  
      if (depts.indexOf('Scrap') !== -1){
        var sData = data.Scrap;
        Object.keys(sData).map((sn)=>{
          var tData = sData[sn];
          let part = tData.part;
  
          let isSerialized = CheckSerialized(sn, part);
          if (isSerialized){
            let idPart =IdPart(sn);
            let invPart = expectedInv[sn]?.part;
            if (part !== invPart && invPart !== undefined){
              if (issues.scrap === undefined){ issues.scrap = {};}
              if (issues.scrap[sn] === undefined){ issues.scrap[sn] = {}; }
              issues.scrap[sn].partIssue = `Part Id'd as ${invPart ? invPart : idPart }`;
            }
          }
  
          try{
            if (Window.parts[tData.part]?.serialized === false){
              sn = tData.part;
            }else if (Window.parts[tData.part]?.serialized === true){
              if (expectedInv[sn] === undefined){
                expectedInv[sn].overScrapped = true;
              }
            }
          }catch(e){ }
  
          if (expectedInv[sn] === undefined){
            expectedInv[sn] = {
              part : tData.part,
              qty : 0,
              overScrapped : true,
            }
            if (issues.scrap === undefined){ issues.scrap = {}; }
            if (issues.scrap[sn] === undefined){ issues.scrap[sn] = {};};
            issues.scrap[sn].issue = 'overScrapped';
            issues.scrap[sn].part = part;

            if (issueCounts[tData.part] === undefined){ issueCounts[tData.part] = {}; }
            if (issueCounts[tData.part].overScrapped === undefined){ issueCounts[tData.part].overScrapped = 0};
            issueCounts[tData.part].overScrapped += (tData.qty || 1);
          }
  
          
  
          if (expectedInv[sn].qty === undefined){ expectedInv[sn].qty = 0; }
          expectedInv[sn].qty = expectedInv[sn].qty - tData.qty;
          if (expectedInv[sn].qty < 1){ delete expectedInv[sn] }
  
          if (movementCounts.scrap === undefined){ movementCounts.scrap = {}; }
          if (movementCounts.scrap[part] === undefined){ movementCounts.scrap[part] = {};};
          if (movementCounts.scrap[part].qty === undefined){ movementCounts.scrap[part].qty = 0;};
          movementCounts.scrap[part].qty += tData.qty;
        });
      }
  
    });
  
    var counts = GetInvCounts(expectedInv);
    Object.keys(lmCounts).map((part)=>{
      if (counts[part] === undefined){ counts[part] = {}; }
      if (counts[part].lmCount === undefined){ counts[part].lmCount = 0; }
      counts[part].lmCount = lmCounts[part].eCount;
    });
  
    Object.keys(movementCounts).map((dept)=>{
      Object.keys(movementCounts[dept]).map((part)=>{
        let qty = movementCounts[dept][part].qty;
        if (counts[part] === undefined){ counts[part] = {}; }
        if (counts[part][dept] === undefined){ counts[part][dept] = 0; }
        counts[part][dept] = qty;
      })
    });

    if(cScans){
      Object.keys(cScans).map((sn)=>{
        let data = cScans[sn];
        let part = data.part;
        let qty = data.qty;

        if (expectedInv[sn] === undefined){
          if (issues.scans === undefined){ issues.scans = {}};
          if (issues.scans[sn] === undefined){
            issues.scans[sn] = {};
          }
          if (Window.parts?.[IdPart(sn)]?.serialized === true){
            if (issues.scans[IdPart(sn)] === undefined){ issues.scans[IdPart(sn)] = {};}
            issues.scans[IdPart(sn)][sn] = {
              unexpectedScan : true,
            };

            if (issueCounts[IdPart(sn)] === undefined){ issueCounts[IdPart(sn)] = {}; }
            if (issueCounts[IdPart(sn)].unexpectedScan === undefined){ issueCounts[IdPart(sn)].unexpectedScan = 0; }
            issueCounts[IdPart(sn)].unexpectedScan += qty;
          }
          
        }
        

        if (counts[part] === undefined){ counts[part] = {}; }
        if (counts[part].count === undefined){ counts[part].count = 0; }
        counts[part].count += qty;
      });
    }

    if (issueCounts){
      Object.keys(issueCounts).forEach((part)=>{
        if (counts[part] === undefined){ counts[part] = {}; }
        if (counts[part].issues === undefined){ counts[part].issues = {}; }
        Object.keys(issueCounts[part]).forEach((issue)=>{
            if (counts[part].issues[issue] === undefined){ counts[part].issues[issue] = 0}
            counts[part].issues[issue] += (issueCounts[part][issue] || 0);
        })
      })
    }

  
    if (!Window.testMode){
        WriteDB('Inventory/Counts', counts);
        WriteDB('Inventory/current', expectedInv); 
        WriteDB('Inventory/issues', issues); 
      
    }
    
  })
  
  



}

//Window.testMode = true;

function GetInvCounts(obj){
  let counts = {};
  Object.keys(obj).map((sn)=>{
    let data = obj[sn];
    if (counts[data.part] === undefined){ counts[data.part] = {} };
    if (counts[data.part].eCount === undefined){ counts[data.part].eCount = 0 };
    counts[data.part].eCount += data.qty;
  });

  return counts;
}

let thang = {
  inventory : {
    current : {
      sn : "x",
      qty : "x",
    },
    issues : {
      dept : {
        sn : {
          issue : 'x',
        }
      }
    },
    scans : {
      location : {
        area : {
          rack : {
            shelf : {
              sns : 'x',
            }
          }
        }
      }
    },
    counts : {
      location : {
        area : {
          rack : {
            shelf : {
              part : "x",
            }
          }
        }
      }
    },

    devices : {
      sn : {
        part : "x",
        qty : "x",
        location : "x",
      }
    }
  },



}

String.prototype.firebaseFormat = function(char){
  if (!char){ char = ""; }
  // eslint-disable-next-line
  let str = (this || "").toString().replaceAll(/[\/\\#$."\[\]]/g, char);
  str = str.toString().replaceAll(/[\s\t]/g,' ').slice(0, 450);
  return str;
}