import React, { useState, useEffect, createRef } from 'react';

function DevTable({ department, data, headers }) {
    
    

    const [Grid, SetGrid] = useState([]);
    const [colWidth, setColWidth] = useState([]);
    useEffect(()=>{
        let values = [];
        let minCols = headers[department].length;
        let cols = [];

        headers[department].map((key)=>{
            cols.push(['180px']);
        });

        setColWidth(cols.join(' '));

        for(let i = 0; i < 50; i++){
            values.push([...Array(minCols).keys()].map(i => ""));
        }

        values = values.map((row)=>{
            if (row.length < minCols){ for(let i = 0; i < minCols - row.length; i++){ row[row.length + i] = ""; }}
            return row.map((col)=>{
                if (!col){ col = ""; }
                let value = col.toString();
                let ref = createRef();
                return {
                    value : value,
                    ref : ref,
                }
            })
        })
        SetGrid(values);
        // eslint-disable-next-line
    },[department,data]);

    const change = (value, row, col)=>{
        if (!value){ value = ""; }
        value = value.toString().split('\n');
        value.map((index, i)=>{
            index = index.toString().split("\t");
            index.map((val, j)=>{
                Grid[row+i][col+j].value = val;
                Grid[row+i][col+j].setValue(val);
            });
        });

    }

    const register = (row, col, setValue, setActive)=>{
        Grid[row][col].setValue = setValue;
        Grid[row][col].setActive = setActive;
    }

    var activeCell = { row : 0, col : 0}
    var activeCells = [];
    const handleClick = (row, col, status, event)=>{
        if (status === ""){
            return;
        }
        if (event.shiftKey === true){
            for(let i = 0; i < row - activeCell.row+1; i++){
                for(let j = 0; j < col-activeCell.col+1 ; j++){
                    Grid[i+activeCell.row][j+activeCell.col].setActive("active");
                    activeCells.push([i+activeCell.row,j+activeCell.col]);
                }
            }
        }else if (event.shiftKey !== true){
            activeCells.map(([i, j])=>{
                Grid[i][j].setActive('');
            });
            activeCells = [];
            Grid[activeCell.row][activeCell.col].setActive('');
            try{
                Grid[row][col].ref.current.focus();
                Grid[row][col].ref.current.select();
            }catch(e){ }
            
            activeCell.row = row;
            activeCell.col = col;
        }
    }

    window.addEventListener('keydown', (event)=>{
        let key = event.key.toString();
        if (key === "Escape"){
            activeCells.map(([i, j])=>{
                Grid[i][j].setActive('');
            });
        }
        if (key === "Delete" || key === "Backspace"){
            activeCells.map(([i, j])=>{
                Grid[i][j].setValue('');
            });
        }
    });

    const [activeIndex, setIndex] = useState(null);
    const [clientX, setClientX] = useState(null);
    const mouseDown = (event, index)=>{
        setClientX(event.clientX);
        setIndex(index);
    }

    const mousemove = (event)=>{
        if (activeIndex === null){ return; }
        let xPos = event.clientX;
        let diff = (xPos - clientX) / 7;
        let cols = colWidth.split(' ');
        let currentWidth = parseInt(cols[activeIndex]);
        if (currentWidth + diff >= 180 && currentWidth + diff < 500){
            cols[activeIndex] = `${(currentWidth + diff)}px`;
            setColWidth(cols.join(' '));
        }
    }

    window.addEventListener('mousemove', mousemove);

    window.addEventListener('mouseup', ()=>{
        if (activeIndex === null){ return; }
        setIndex(null);
        window.removeEventListener('mousemove', mousemove);
    });


    const scrollRef = createRef();
    const scroll = (e)=>{ scrollRef.current.scrollLeft = e.target.scrollLeft; }

    return (
        <div className = "flex flex-col h-[100%] w-[100%] darkBg">
            <div ref = {scrollRef}  className = "devHeader xScroll" style = {{ minHeight : "48px", display : 'grid', gridTemplateColumns : colWidth }}>
                {headers[department].map((key, i)=>{
                    return (
                        <div className = "relative" key = {`Header${i}l`}>
                            <div key = {`Header${i}`} className = "devCell">{key}</div>
                            <div key = {`colResizer${i}`} className = "colResizer" onMouseDown = {(e)=>{mouseDown(e, i)}}></div>
                        </div>
                    )
                })}
            </div>
            <div className = "devTable" onScroll = {scroll} >
                {Grid.map((row, i)=>{
                    return <GenRow data = {row} row = {i} change = {change} register = {register} key = {`row${i}`} clickhandler = {handleClick} style = {{height : "40px", display : "grid", gridTemplateColumns :  colWidth}}/>
                 })
                }

            </div>
        </div>
  )
}

function GenRow({data, row, change, register, clickhandler, style}){

    
    return (
        <div className = "devRow" style = {style}>
            {data.map((cell, i)=>{
                return <GenCell data = {cell} row = {row} col = {i} change = {change} register = {register}  key = {`row${row}col${i}`} clickhandler = {clickhandler}/>
            })}
        </div>
    )
}

function GenCell({data, row, col, change, register, clickhandler}){
    const [value, setValue] = useState(null);
    const [active, setActive] = useState("");
    useEffect(()=>{
        setValue(data.value);
    }, [data]);

    register(row, col, setValue, setActive);
    
    return (
        <div className = "relative devCell0">
            <div className = {`devCell1 ${active}`} onClick = {(e)=>{  clickhandler(row, col, 'active', e); }}></div>

            <textarea ref = {data.ref} className = "devCell" value = { value || ""} onChange = {(e)=>{
            change(e.target.value, row, col);
            }} autoComplete = "off" autoCorrect='off' onBlur = {(e)=>{ data.ref.current.scrollTop = "0"; clickhandler(row, col, '', e); data.ref.current.blur();}}></textarea>
        </div>
        
    )
}


export default DevTable;