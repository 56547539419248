import React, { useEffect, useState } from 'react';
import { ReadDB,GetLastDayInMonth, GetNextDay,formatDate, GetLastDay, GetDayOfWeek, ConvertToDate, GetCurrentDate, GetLongDate} from "../imports";

function AdminCalendar({ date, setData }) {
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const departments = ['Shipping','Receiving','Detamper','Repair','Diagnostics','Cleaning','Firmware','Rki'];
    const days = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"];
    const [dGrid, setDateGrid] = useState([]);

    const [selectedDate, setDate] = useState(GetCurrentDate().toString().replaceAll('/','-'));
    const [viewClass, setViewClass] = useState('absolute dayView'); // activeDayView
    const [viewDate, setViewDate] = useState('');

    const toggleView = (date)=>{
        setViewDate(date);

        if (viewClass === "absolute dayView"){
            setViewClass('absolute dayView activeDayView');
        }else{
            setViewClass('absolute dayView');
        }
        
    }

    useEffect(()=>{
        window.addEventListener('keydown', (event)=>{
            let key = event.key.toString();
            if (key === "Escape"){
                setViewClass('absolute dayView');
            }
        })
    },[]);
    

    useEffect(()=>{
        let date = selectedDate;
        if (Window.counts[date.toString().slice(0,2)+'-'+date.toString().slice(-2)] === undefined){
            setTimeout(()=>{
                PullData();
            },150);
        }else{
            PullData();
        }
        if (viewClass !== "absolute dayView"){
            setViewClass('absolute dayView');
        }
    }, [selectedDate]);
    

    const PullData = ()=>{
        let date = selectedDate;
        if (!selectedDate){ return; }
        let dateGrid = [];
        date = date.toString().slice(0,2)+'-'+date.toString().slice(-2);
        if (Window.counts[date] === undefined){
            ReadDB(`MainCounts/${date}`, (data)=>{
                Window.counts[date] = data;
            });
        }
        let fullDate = date.toString().split('-');
        fullDate = `${fullDate[0]}-01-${fullDate[1]}`;
        let previousDate = GetLastDay(fullDate);
        previousDate = previousDate.split('/');
        previousDate = `${previousDate[0]}-${previousDate[2]}`;
        if (Window.counts[previousDate] === undefined){
            ReadDB(`MainCounts/${previousDate}`, (data)=>{
                Window.counts[previousDate] = data;
                setData(Window.counts);
            });
        }


        setData(Window.counts);

        date = date.toString().split('-');
        date = date[0].toString()+'-01-'+date[1].toString();
        let startDay = ConvertToDate(date);
        startDay = new Date(startDay.getFullYear(), startDay.getMonth(), 35);
        for(let i = 0; i < 7; i++){
            if (GetDayOfWeek(formatDate(startDay)) === "Sunday"){
                break;
            }
            startDay = new Date(new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate() - 1));
        }
        for(let i = 0; i < 35; i++){
            let date = formatDate(startDay);
            dateGrid.push(date);
            startDay = new Date(startDay.getFullYear(),startDay.getMonth(), startDay.getDate() - 1);
        }
        dateGrid = dateGrid.reverse();
        setDateGrid(dateGrid);
    }

    useEffect(()=>{
        setDate(date);
    }, [date]);

  return (
    <div className = "absolute inset-0">
        <div className = "header h-[42px] w-[100%] grid grid-cols-7 grid-rows-1">
            { days.map((day)=>{
                return <div key = {day} className = "noSelect dayCol greyFont font-medium flex items-center justify-center w-[100%] h-[100%] text-sm">{day}</div>
            })}
        </div>
        <div className = "mainCal w-[100%] grid grid-cols-7 grid-rows-5" style = {{ height : "calc(100% - calc(42px + 0px))"}}>
            {dGrid.map((date)=>{
                let active = true;
                if (date.toString().slice(0,2)+'-'+date.toString().slice(-2) !== selectedDate.toString().slice(0,2)+'-'+selectedDate.toString().slice(-2)){
                    active = false;
                }
                return <CalendarCell key = {date} date = {date} active = {active} onClick = {toggleView} />;
            })}
        </div>

        <div className = {viewClass} >
            <div className = "header m-2 flex flex-row h-[24px] items-center">
                <div className = "flex flex-row gap-1">
                    <div className = "noSelect font-medium text-sm greyFont">{GetLongDate(viewDate).toString().split('/')[0]}</div>
                    <div className = "noSelect font-medium text-sm">{GetLongDate(viewDate).toString().split('/')[1]}</div>
                </div>
                <div className = "closeContainer absolute right-2 h-[32px] w-[32px] flex items-center justify-center" onClick = {()=>{toggleView('')}}>
                    <svg height = "24px" width = "24px" className = "whiteSvg" viewBox="0 0 24 24" fill="none"> <g clipPath="url(#clip0_429_11083)"> <path d="M7 7.00006L17 17.0001M7 17.0001L17 7.00006" stroke="#292929" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/> </g> <defs> <clipPath id="clip0_429_11083"> <rect width="24" height="24" fill="white"/> </clipPath> </defs> </svg>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminCalendar;

function CalendarCell({date, active, onClick }){
    const departments = ['Shipping','Receiving','Detamper','Repair','Diagnostics','Cleaning','Firmware','Rki'];
    const [activeDate, setDate] = useState(date);
    const [isActive, setActive] = useState('calCell relative flex flex-col justify-evenly');
    const [hours, setHours] = useState(0);
    const [employees, setEmployees] = useState([]);

    useEffect(()=>{
        setDate(date);
        let hourCount = 0;
        let employees = [];
        let today = GetCurrentDate();

        if (active){
            if (date === today){
                setActive('calCell relative flex flex-col justify-evenly today');
            }else{ setActive('calCell relative flex flex-col justify-evenly'); }
            
            let month = date.toString().slice(0,2)+'-'+date.toString().slice(-2);
            if(Window.counts[month] !== undefined){
                if (Window.counts[month][date.toString().replaceAll('/','-')] !== undefined){
                    let dateVals = Window.counts[month][date.toString().replaceAll('/','-')];
                    departments.map((dept)=>{
                         dept = dept.toString().toLowerCase();
                         let ignore = ['inventory','scrap','transfers'];
                         if (ignore.indexOf(dept) !== -1){ return; }
                         let dateCounts = dateVals[dept]?.data;
                         if (!dateCounts){ return; }
                         Object.keys(dateCounts).map((employee)=>{
                            if (dateCounts[employee].hours !== undefined){
                                hourCount += dateCounts[employee].hours;
                            }
                            employees.push([employee, dept]);

                         });
                    });
                    setEmployees(employees);

                    setHours(hourCount);
                }
            }
        }else{ setActive('calCell relative flex flex-col justify-evenly inActive'); }
        
    },[date, active]);


    return (

        <div className = {isActive} onClick = {()=>{onClick(activeDate); }}>
            <div className = "dateCell noSelect greyFont">{parseInt(activeDate.toString().slice(3,5)).toString()}</div>
            
            <div className = "cellRows">
                {
                    employees.map((row)=>{
                        let dept = row[1];
                        let e = row[0];
                        return (
                            <div className = {`eCircle eCircle${dept}`} key = {`eCircle${date}${e}${dept}`}></div>
                        )
                    })
                }
            </div>
            <div className = "cellContainer flex flex-row justify-start gap-1 ml-2 opacity-80">
                <svg className = "whiteSvg opacity-60 mb-2 " width="22px" height="22px" viewBox="0 0 24 24" fill="none">
                    <rect width="24" height="24" fill="none"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM15.8321 14.5547C15.5257 15.0142 14.9048 15.1384 14.4453 14.8321L11.8451 13.0986C11.3171 12.7466 11 12.1541 11 11.5196L11 11.5L11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7L13 11.4648L15.5547 13.1679C16.0142 13.4743 16.1384 14.0952 15.8321 14.5547Z" fill="#323232"/>
                </svg>
                <div className = "eCount noSelect">{hours}</div>
            </div>
        </div>

    )
}


let thang = {
    // 7 x 5 Grid last day first

}



/* 
    <div className = "cellContainer flex flex-row justify-evenly">
        <svg className = "whiteSvg opacity-60 relative" width="22px" height="22px" viewBox="0 0 24 24" fill="none">
            <rect width="24" height="24" fill="none"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8Z" fill="#323232"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.43094 16.9025C7.05587 16.2213 9.2233 16 12 16C14.771 16 16.9351 16.2204 18.5586 16.8981C20.3012 17.6255 21.3708 18.8613 21.941 20.6587C22.1528 21.3267 21.6518 22 20.9592 22H3.03459C2.34482 22 1.84679 21.3297 2.0569 20.6654C2.62537 18.8681 3.69119 17.6318 5.43094 16.9025Z" fill="#323232"/>
        </svg>
        <div className = "eCount noSelect">{eCount}</div>
    </div>


*/