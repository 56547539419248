import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts'

function LineChart({ series , labels, department }){

  const [labelz, setLabelz] = useState(labels);
  const [data, setData] = useState(series);
  useEffect(()=>{
    setLabelz(labels);
    setData(series);
  },[series, labels]);


  const options = {
    chart: {
      foreColor : "rgb(170, 170, 170)",
      height : 'auto',
      type: 'area',
      toolbar: {
        show: false
      },
      zoom : {
        enabled : false,
      },
    },
    colors : ["#34b1eb","rgba(167, 176, 181, 0.5)"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width : 2,
    },
    
    legend : {
      show : false,
    },

    xaxis: {
      type: 'category',
      categories: labelz,
    },

    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        return (
          
          '<div class="arrow_box">' +
            '<div class = "arrow_title">'+labels[dataPointIndex]+'</div>'+
            "<span>" +
              '<div class="arrow_box_2">'+
                `Target` +
              '</div>' +
            
            '<div class="arrow_box_2">'+
                series[1][dataPointIndex] +
              '</div>' +
            "</span>" +
            "<span>" +
              '<div class="arrow_box_2">'+
                `${department.toString().replace('ing','ed')}` +
              '</div>' +
            
            '<div class="arrow_box_2">'+
                series[0][dataPointIndex] +
              '</div>' +
            "</span>" +
            
           
          "</div>"
        );
      }
    },

    grid : {
      show : true,
      borderColor : 'rgba(230,230,230,0.05)',
    }
  };

  return (
    <div className = "absolute inset-1 right-0 altCard overflow-hidden">
      <Chart height = "100%" options={ options } type="area" series={ data } />
    </div>
  );
}

export default LineChart;